define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "access-to": "Access To",
    "access-to-clients": "Access to Clients",
    "access-to-users": "Access to Users",
    "account-name": "Account Name",
    "account-settings": "Account Settings",
    "account-settings-update-failed": "Failed to update Account Settings",
    "account-settings-update-success": "Account Settings updated successfully",
    "activate": "Activate",
    "activate-client-confirmation-message": "Are you sure you want to activate this client?",
    "activate-user-confirmation-message": "Are you sure you want to activate this user?",
    "activating": "Activating...",
    "active": "Active",
    "add": "Add",
    "add-aggregation-field": "Add Aggregation Field",
    "add-business-hours": "Add Business hours",
    "add-category-field": "Add Category field",
    "add-columns-field": "Add Column field",
    "add-data-bar": "Add Data bar",
    "add-drill-down-field": "Add Drilldown Field",
    "add-end-field": "Add End field",
    "add-field": "Add Field",
    "add-field-description": "Add a field",
    "add-field-error-message": "Field name cannot be empty",
    "add-field-field": "Add Field",
    "add-filter-field": "Add Filter field",
    "add-from-field": "Add From field",
    "add-from-template": "Add from template",
    "add-group-by-field": "Add Group By field",
    "add-group-field": "Add Group field",
    "add-label-field": "Add Label field",
    "add-region-field": "Add Region Field",
    "add-rows-field": "Add Row field",
    "add-sort-field": "Add sort field",
    "add-start-field": "Add Start field",
    "add-target-field": "Add Target field",
    "add-task-field": "Add Task field",
    "add-to-field": "Add To field",
    "add-top-n-filter-field": "Add Top-N Filter field",
    "add-value-field": "Add Value field",
    "add-values-field": "Add Value field",
    "add-visualization": "Add Visualization",
    "add-x-axis-field": "Add X-Axis field",
    "add-y-axis-field": "Add Y-Axis field",
    "add-y-axis-left-field": "Add Y-Axis Left field",
    "add-y-axis-right-field": "Add Y-Axis Right field",
    "adding": "Adding...",
    "admin": "Admin",
    "after-data-refresh": "After Data Refresh",
    "aggregation-type-error-message": "Please select an aggregation type",
    "aircall": {
      "add-account": "Add Aircall Account",
      "company-name": "Company Name",
      "linked-accounts": "Linked Aircall Accounts",
      "no-account-added": "No aircall account added.",
      "numbers": "Aircall Numbers",
      "title": "Aircall",
      "users": "Aircall Users"
    },
    "aircall-source-type": "Aircall",
    "all-options-selected": "All {entity} selected",
    "all-users": "All Users",
    "allow-csv-import": "Allow CSV import",
    "always-show-values": "Always show values",
    "api-key": "API Key",
    "app-description": {
      "aircall": {
        "basic-info": "HappyFox BI - Aircall integration can automatically bring your Aircall data into HappyFox BI, so you can run  reports and provide your internal teams with even greater visibility into the call activity happening in Aircall.",
        "more-info": {
          "four": "4. Share reports seamlessly with other internal teams using HappyFox BI.",
          "one": "1. Run advanced reports and analytics that aren’t available in Aircall.",
          "three": "3. Access all Aircall account data including call logs, call duration, call status etc.",
          "two": "2. Create reports and dashboards in HappyFox BI that incorporate Aircall data and data from other sources like HappyFox Helpdesk."
        },
        "more-info-heading": "With HappyFox BI - Aircall Integration, you can"
      },
      "happyfox": {
        "basic-info": "Integrate HappyFox Help desk with HappyFox BI to slice and dice your support data the way you want, to make informed decisions, and to gain insights into your customer support activity.",
        "more-info": {
          "one": "1. Analyze and track agents performance, CSAT scores, tickets inflow, and do much more.",
          "three": "3. Share reports and collaborate with your colleagues, during report creation and analysis.",
          "two": "2. Visualize your support data on a wide variety of charts, pivot tables, KPI widgets, and tabular view components to build insightful reports and dashboards."
        },
        "more-info-heading": "With HappyFox BI - Helpdesk Integration, you can"
      },
      "salesforce": {
        "basic-info": "Salesforce is the industry’s leading cloud-based CRM software.",
        "how-to-setup": {
          "create-connected-app": "1. Open your salesforce application and create a connected app.",
          "ensure-appropriate-permissions": "3. Ensure that the application has appropriate permissions.",
          "enter-credentials": "5. In HappyFox BI, navigate back to salesforce app’s manage screen and enter the “Consumer Key” and “Consumer Secret” from above.",
          "find-consumer-key-consumer-secret": "4. Find the “Consumer Key” and “Consumer Secret” from the connected app.",
          "save-and-activate": "6. Save and activate the integration.",
          "use-oauth": "2. Use OAuth for authentication using the HappyFox callback URL as given below."
        },
        "pre-requisites": {
          "content": "Rest API needs to be enabled for the salesforce account.",
          "heading": "Pre-Requisites"
        }
      },
      "wrike": {
        "basic-info": "Use HappyFox BI - Wrike integration to capture and analyze your project management data effortlessly.",
        "more-info": {
          "four": "4. Compare metric across different timelines to identify underlying patterns and problems.",
          "one": "1. Get 360 view of your project's performance. HappyFox BI lets you customize and build your reports in the way you want them — with absolutely no coding and minimal clicks.",
          "three": "3. Apply filters and Sort to slice and dice to your important project metrics. Delve deeper and uncover insights from your Wrike data.",
          "two": "2. Create reports and dashboards in HappyFox BI that mix and match Wrike data and data from other products like HappyFox Helpdesk or Aircall."
        },
        "more-info-heading": "Salient Features:"
      }
    },
    "applied-page-filters": "Applied Page Filters",
    "applied-report-filters": "Applied Report Filters",
    "apply": "Apply",
    "apps": "Apps",
    "apps-caps": "Apps",
    "apps-full-caps": "APPS",
    "auto-detect-fields": "Auto create/update fields detected from data",
    "available-across-all-accounts": "Available across all accounts",
    "bar-color": "Bar Color",
    "begin-import": "Begin Import",
    "boolean-data-type": "Boolean",
    "bottom-left": "Bottom Left",
    "bottom-right": "Bottom Right",
    "bucket-value-default": "All other values",
    "bucket-values-error-message": "Please fill in all the inputs for Group values",
    "bulk-actions": {
      "initiation-failure": "Something went wrong with initiating your mass action."
    },
    "bulk-clients-actions": {
      "heading": "Bulk Editing - {selectedCount, plural, =1 {1 client} other {# clients}} selected",
      "update-success-message": "The selected clients have been updated successfully"
    },
    "bulk-reports-actions": {
      "heading": "Bulk Editing - {selectedCount, plural, =1 {1 report} other {# reports}} selected",
      "update-success-message": "The selected reports have been updated successfully"
    },
    "bulk-users-actions": {
      "heading": "Bulk Editing - {selectedCount, plural, =1 {1 user} other {# users}} selected",
      "update-success-message": "The selected users have been updated successfully"
    },
    "business-hours": "Business hours",
    "business-hours-create-warning": "You are about to create a business hour",
    "business-hours-delete-confirmation-message": "Are you sure you want to delete this business hours?",
    "business-hours-delete-warning": "Are you sure you want to delete this business hour?",
    "business-hours-task-process-note": "<span class=\"font-semi-bold\">Note:</span> We will be updating the metrics to reflect the new Business hours. This activity may take some time based on the volume of data present.",
    "business-hours-update-failed": "Failed to update Business Hours",
    "business-hours-update-success": "Business Hours updated successfully",
    "business-hours-update-warning": "Are you sure you want to update this business hour?",
    "business-hours-validation": {
      "field-required": "This field is required",
      "invalid-end-time": "End time must be after start time",
      "no-active-day": "Atleast one work day should be active"
    },
    "business-hours-visualizations-title": "Affected Visualizations for Business hours",
    "business-hours-week": {
      "day-0": "M",
      "day-1": "T",
      "day-2": "W",
      "day-3": "T",
      "day-4": "F",
      "day-5": "S",
      "day-6": "S",
      "full-form": {
        "day-0": "Monday",
        "day-1": "Tuesday",
        "day-2": "Wednesday",
        "day-3": "Thursday",
        "day-4": "Friday",
        "day-5": "Saturday",
        "day-6": "Sunday"
      }
    },
    "business-hours-{name}-create-failed": "Failed to create Business Hours \"{name}\"",
    "business-hours-{name}-create-success": "Business Hours \"{name}\" created successfully",
    "business-hours-{name}-delete-failed": "Failed to delete Business hours \"{name}\"",
    "business-hours-{name}-delete-success": "Business hours \"{name}\" deleted sucessfully",
    "business_hours": "Business Hours",
    "business_hours_description": "Create new Business Hours, edit and delete any existing Business Hours",
    "cancel": "Cancel",
    "category": "Category",
    "change-access-to": "Change Access to",
    "change-format": "Change Format",
    "choose-a-dataset": "Choose a Dataset",
    "choose-file": "Choose file",
    "choose-format": "Choose Format",
    "choose-more-clients": "Choose more clients",
    "choose-more-reports": "Choose more reports",
    "choose-more-users": "Choose more users",
    "choose-theme": "Choose Theme",
    "choose-{entity}": "Choose {entity}",
    "client": "Client",
    "client-activate-failed": "Failed to activate client",
    "client-activate-success": "Client activated successfully",
    "client-deactivate-failed": "Failed to deactivate client",
    "client-deactivate-success": "Client deactivated successfully",
    "client-report-subscription-dependant-message": "Client {client} is a recipient in report subscription(s) {reportSubscriptions}",
    "client-update-failed": "Failed to update client",
    "client-update-success": "Client updated successfully",
    "clients": "Clients",
    "clone": "Clone",
    "clone-and-edit": "Clone & Edit",
    "clone-and-view": "Clone & View",
    "clone-visualization": "Clone Visualization",
    "cloning": "Cloning..",
    "close": "Close",
    "collapse-all": "Collapse All",
    "color": "Color",
    "color-scale": "Color Scale",
    "columns": "Columns",
    "columns-empty-label": "No Column fields selected",
    "columns-label": "Columns Label",
    "combine-value-default-help-text": "All other values ",
    "combine-values": "Combine Values",
    "combine-values-error-message": "Please fill in all the inputs for Combine values",
    "compare-dates": "Compare Dates",
    "comparing-with": "Comparing with ",
    "comparison": "Comparison",
    "comparison-metric": "Comparison Metric",
    "comparison-metric-tooltip-text": "This setting is applicable when you compare the value with another date range",
    "configure-gauge-bands": "Configure Gauge bands",
    "confirm-fields-import": "Confirm fields for import",
    "confirm-password": "Confirm Password",
    "connect-empty-values": "Connect empty values",
    "copied": "Copied",
    "copy": "Copy",
    "copy-to-clipboard": "Copy to clipboard",
    "create": "Create",
    "create-data-set": "Create Data Set",
    "create-data-source": "Create Data Source",
    "create-new": "Create New",
    "create-subscription": "Create Subscription",
    "create_report_templates": "Create Report Templates",
    "create_report_templates_description": "Save a Report as a Template",
    "create_sample_visualizations": "Create Visualization Templates",
    "create_sample_visualizations_description": "Save a Visualisation as a Template",
    "creating": "Creating...",
    "currency": "Currency",
    "currency-data-type": "Currency",
    "current-account": "Current Account",
    "current-period": "Current Period",
    "custom": "Custom",
    "custom-source-type": "Custom",
    "daily": "Daily",
    "data-bars": "Data Bars",
    "data-bars-description": "Format table columns with bars based on their values",
    "data-sources": "Data Sources",
    "data-table-records-message": "Showing {displayedRecords} out of {totalRecords}",
    "data-tile-label-help-text": "Represents a single label in a metric summary",
    "data-tile-value-help-text": "Numeric field representing a single sorted value in a metric summary",
    "data-type-format-change-failure-message": "Failed to change format",
    "data-type-format-change-note": "<span class=\"font-semi-bold\">Note:</span> Upon save, we will be updating the existing visualizations to reflect the new format. This activity may take some time.",
    "data-type-format-change-success-message": "Format changed successfully",
    "dataset": "Dataset",
    "dataset-data-type": "Dataset",
    "dataset-fields": "Dataset Fields",
    "date-data-type": "Date",
    "date-range-for-report": "Date range for report",
    "date-ranges": {
      "last-14-days": "Last 14 Days",
      "last-30-days": "Last 30 Days",
      "last-365-days": "Last 365 Days",
      "last-7-days": "Last 7 Days",
      "last-month": "Last Month",
      "last-period": "Last Period",
      "last-six-months": "Last Six Months",
      "last-three-months": "Last Three Months",
      "last-week": "Last Week",
      "last-year": "Last Year",
      "this-month": "This Month",
      "this-week": "This Week",
      "this-year": "This Year",
      "today": "Today",
      "yesterday": "Yesterday"
    },
    "deactivate": "Deactivate",
    "deactivate-client-confirmation-message": "Are you sure you want to deactivate this client?",
    "deactivate-user-confirmation-message": "Are you sure you want to deactivate this user?",
    "deactivated": "Deactivated",
    "deactivating": "Deactivating...",
    "decimal-data-type": "Decimal",
    "default-value-label": "Group all other values",
    "delete": "Delete",
    "delete-business-hours-confirmation-description": "Deleting this business hour will affect the following visualizations:",
    "delete-business-hours-dependant-visualizations-description": "List of Visualizations that will be affected by deleting this business hours",
    "delete-page-disabled-message": "Cannot delete last page of the report.",
    "delete-report-disabled-message": "Cannot delete last report of the account.",
    "deleting": "Deleting...",
    "description": "Description",
    "dimension": "Dimension Fields",
    "disabled-aggregation-type-help-text": "You have chosen \"No aggregation\" for other Values. You cannot have a combination of aggregated and non-aggregated values.",
    "disabled-no-aggregation-type-help-text": "You have specified an aggregation for other Values. You cannot have a combination of aggregated and non-aggregated values.",
    "discard": "Discard",
    "display-name": "Display Name",
    "doughnut-chart-field-help-text": "Select field to view the slices based on the field values",
    "doughnut-chart-value-help-text": "Numeric field representing the proportion of each slice",
    "download": "Download Raw Data",
    "duplicate_holiday_error_message": "Holiday date, name should be unique",
    "edit": "Edit",
    "edit-aggregation-field": "Edit Aggregation Field",
    "edit-business-hours": "Edit Business hours",
    "edit-category-field": "Edit Category field",
    "edit-client": "Edit Client",
    "edit-columns-field": "Edit Column field",
    "edit-data-bar": "Edit Data bar",
    "edit-drill-down-field": "Edit Drilldown Field",
    "edit-end-field": "Edit End field",
    "edit-field": "Edit Field",
    "edit-field-description": "Edit a field",
    "edit-field-field": "Edit Field",
    "edit-filter-field": "Edit Filter field",
    "edit-group-by-field": "Edit Group By field",
    "edit-group-field": "Edit Group field",
    "edit-label-field": "Edit Label field",
    "edit-page": "Edit Page",
    "edit-region-field": "Edit Region Field",
    "edit-report": "Edit Report",
    "edit-report-template": "Edit Report Template",
    "edit-rows-field": "Edit Row field",
    "edit-sort-field": "Edit Sort field",
    "edit-start-field": "Edit Start Field",
    "edit-subscription-to-report": "Edit Subscription to a report",
    "edit-target-field": "Edit Target field",
    "edit-task-field": "Edit Task field",
    "edit-template": "Edit Template",
    "edit-top-n-filter-field": "Edit Top-N Filter field",
    "edit-user": "Edit User",
    "edit-value-field": "Edit Value field",
    "edit-values-field": "Edit Value field",
    "edit-visualization": {
      "add-benchmark": "Add Benchmark",
      "add-gauge-band": "Add Gauge band",
      "advanced": "Advanced",
      "aggregate-field-name": "Aggregate Field Name",
      "aggregation-field": "Aggregation Field",
      "average": "Average",
      "benchmarks": "Benchmarks",
      "clone-title": "Clone Visualization",
      "color-mode": "Color mode",
      "columns": "Columns",
      "create-title": "Create Visualization",
      "custom": "Custom",
      "data-set": "Dataset",
      "data-source": "Data Source",
      "date-range": "Date Range",
      "date-range-field": "Date range field",
      "drill-down-fields": "Choose fields for drilldown",
      "edit-derived-field-permission-message": "You do not have permission to edit the field",
      "enable-top-n-filter": "Enable Top N Filter",
      "enter-percentile-value": "Enter Percentile Value",
      "enter-top-n": "Enter Top N",
      "field-filters-help-text": "Allows the ability to choose fields to filter the visualization when in a page.",
      "field-name": "Field Name",
      "filter-help-text": "Applies directly to the current visualization",
      "filters-and-sort": "Filters & Sort",
      "formula": "Formula",
      "general": "General",
      "include-percentages": "Include Percentages",
      "include-totals": "Include Totals",
      "line": "Line",
      "live-preview": "Live Preview",
      "name": "Name",
      "opted-in-page-level-fields": "Select page level fields whose filters be honoured",
      "opted-in-report-level-fields": "Select report level fields whose filters be honoured",
      "percentile": "Percentile",
      "pivot-table": "Pivot Table",
      "primary": "Primary",
      "range": "Range",
      "raw-data": "Raw Data",
      "rows": "Rows",
      "select-aggregation-field-type": "Select Aggregation Type",
      "select-field": "Select Field",
      "select-fields-filters": "Select fields for filters",
      "select-type": "Select Type",
      "select-value-type": "Select Value type",
      "settings": "Settings",
      "table-formatting": "Table Formatting",
      "target-field": "Target Field",
      "title": "Edit Visualization",
      "top-n-aggregation-help-text": "Select a numeric field that needs to be aggregated for the Top N Filter",
      "top-n-field": "Top N Field",
      "top-n-field-help-text": "Select a field to be sorted using the Top N filter",
      "top-n-filter": "Top N Filter",
      "update-benchmark": "Update Benchmark",
      "update-gauge-band": "Update Gauge band",
      "values": "Values",
      "visualization-settings": "Visualization Settings",
      "visualization-type": "Visualization Type"
    },
    "edit-x-axis-field": "Edit X-Axis field",
    "edit-y-axis-field": "Edit Y-Axis field",
    "edit-y-axis-left-field": "Edit Y-Axis Left field",
    "edit-y-axis-right-field": "Edit Y-Axis Right field",
    "email": "Email",
    "email-reset-password": "Enter your email address and we'll send you a link to reset your password.",
    "email-reset-password-success": "Email with reset password link is sent to <span class=\"font-semi-bold\">{email}</span>",
    "end": "End",
    "end-time": "End Time",
    "enter-page-name": "Enter Page Name",
    "enter-text": "Enter Text",
    "enter-value": "Enter value",
    "entity-too-large-error": "Request entity too large",
    "existing-fields": "Existing Fields",
    "exit-fullscreen": "Exit full screen",
    "expand-all": "Expand All",
    "export_raw_data": "Export Raw Data",
    "export_raw_data_description": "Download a visualization raw data as CSV file",
    "field": "Field",
    "field-information": "Field Information",
    "field-name": "Field Name",
    "fields": "Fields",
    "fields-setup": "Fields Setup:",
    "fields-setup-message": "Select appropriate type for the fields",
    "filter-by": "Filter By:",
    "filter-field-description": "Applies the field to filter across the visualization",
    "first-name": "First Name",
    "forgot-password": "Forgot password?",
    "format": "Format",
    "formula-field-no-delete": "You can't delete this field",
    "formula-field-no-edit": "You can't edit this field",
    "frequency": "Frequency",
    "from": "From",
    "fullscreen": "Full screen",
    "funnel-chart-field-help-text": "Represents each stage in the funnel",
    "funnel-chart-value-help-text": "Numeric field representing the area of each stage",
    "generate-api-key": "Generate",
    "generic-error": "Something went wrong",
    "go-to-login": "Go to Login",
    "go-to-old-create": "Go to old create",
    "go-to-old-edit": "Go to old edit",
    "go-to-reports": "Go to reports",
    "group-label": "Group Label",
    "group-threshold": "Group Threshold (%)",
    "group-threshold-help-text": "All the field values contributing less than the threshold will be grouped together",
    "group-values": "Group Values",
    "happyfox": {
      "title": "HappyFox"
    },
    "happyfox-bi": "HappyFox BI",
    "happyfox-bi-logo": "Happyfox BI logo",
    "happyfox-source-type": "HappyFox Helpdesk",
    "happyfoxchat-source-type": "HappyFox Chat",
    "help-reset-password": "Help me to reset the password",
    "hide-labels": "Hide labels",
    "hide-legend": "Hide legend",
    "hide-values": "Hide Values",
    "holidays-list": "Holidays list",
    "identify-region-by-iso": "Identify region by ISO code",
    "import": "Import",
    "import-completed": "Import successfully completed",
    "import-failure": "Import failure",
    "importing": "Importing...",
    "include-averages": "Include Averages",
    "include-percentages": "Include Percentages",
    "include-report-link": "Include link to report",
    "include-totals": "Include Totals",
    "int-data-type": "Number",
    "internal-server-error": "There has been a programming error and our engineers have been notified.",
    "invalid-email": "Invalid Email",
    "invite": "Invite",
    "invite-client": "Invite Client",
    "invite-client-success-message": "The Invitation has been sent to the client successfully",
    "invite-user": "Invite User",
    "invite-user-success-message": "The Invitation has been sent to the user successfully",
    "invite_client": "Invite Client",
    "invite_client_description": "Invite new clients into the application",
    "invite_user": "Invite User",
    "invite_user_description": "Invite new users into the application",
    "inviting": "Inviting...",
    "join-type": "Join Type",
    "label": "Label",
    "labels": "Labels",
    "last-name": "Last Name",
    "last-sync-{time}": "Last Synced At: {time}",
    "legend": "Legend",
    "legend-label": "Legend Labels",
    "life-cycle-chart-from-field-help-text": "The field used to depict the source of each flow",
    "life-cycle-chart-to-field-help-text": "The field used to depict the destination of each flow",
    "life-cycle-chart-value-help-text": "Numeric field representing the weight of each flow",
    "limit-no-of-records-to-display": "Limit the number of records to display",
    "list-data-type": "List",
    "list-of-fields": "List of fields",
    "loading": "Loading...",
    "logging-in": "Logging in...",
    "login": "Login",
    "login-error": "Please check your credentials",
    "login-happyfox-bi": "Login to <span class=\"font-semi-bold\">HappyFox BI</span>",
    "logout": "Logout",
    "lost-content": "You seem to have entered an invalid URL",
    "lost-header": "Broken Link!",
    "manage": "Manage",
    "manage-all-report-subscriptions": "Manage all report subscriptions",
    "manage-clients": "Manage Clients",
    "manage-reports": "Manage Reports",
    "manage-users": "Manage Users",
    "manage_account_settings": "Manage Account Settings",
    "manage_account_settings_description": "Configure Account Settings like Account Timezone",
    "manage_apps": "Manage Apps",
    "manage_apps_description": "Configure integration with third-party applications",
    "manage_data_sources": "Manage Data Sources",
    "manage_data_sources_description": "Configure Data Sources from HappyFox applications, third-party applications and import custom Datasets as CSV",
    "manage_reports": "Manage Reports",
    "manage_reports_description": "Bulk-edit the Report Access permissions for users and clients from Manage Reports page",
    "manage_users": "Manage Users",
    "manage_users_description": "Edit and modify user details, activate/de-activate users and manage report access permissions from User Management page",
    "mandatory-running-days-validation": "At least one of the days must be active",
    "map-chart-region-help-text": "The field to be used for location",
    "map-chart-value-help-text": "Numeric field that needs to be aggregated for each region",
    "matching-rows-{count}": "The selection has matched {count} out of the latest available 50 rows from each dataset",
    "max": "Max",
    "max-color": "Max color",
    "max-records-tooltip-text": "Maximum of 200 records will be shown",
    "maximize": "Maximize",
    "maximum": "Maximum",
    "merge-datasets": "Merge",
    "merge-datasets-info": "Select matching fields to merge datasets",
    "message": "Message",
    "metric": "Metric Fields",
    "min": "Min",
    "min-color": "Min color",
    "minimize": "Minimize",
    "minimum": "Minimum",
    "missing-value-label": "Want to include missing values?",
    "missing-values": "Missing values",
    "module-switcher": {
      "chat-name": "Chat",
      "chatbot-name": "Chatbot",
      "helpdesk-name": "Help Desk",
      "in-app-chat-name": "In-App Chat",
      "see-all-datasources": "See all data sources",
      "workflows-name": "Workflows"
    },
    "monthly": "Monthly",
    "monthly-day-label": "Enter day between 1 - 31",
    "monthly-day-validation": "Specified day must be between 1 - 31",
    "more-{count}": "{count} more",
    "move": "Move",
    "move-visualization": "Move Visualization",
    "moving": "Moving...",
    "my-settings": "My Settings",
    "name": "Name",
    "new": "New",
    "new-aggregation": "New Aggregation?",
    "no-business-hours": "No business hours found",
    "no-caps": "NO",
    "no-change": "No change",
    "no-clients-available": "No clients available",
    "no-data-found-comparison": "No data found for this period",
    "no-data-sets": "No data sets available",
    "no-data-sources": "No data sources available",
    "no-field-selected": "No Field Selected",
    "no-fields-found": "No fields found",
    "no-fields-present-in-dataSet": "There are no fields in this Dataset",
    "no-manage-users-permission-empty-state-message-clients": "You can invite new clients, but modifying existing clients requires additional permissions.",
    "no-manage-users-permission-empty-state-message-users": "You can invite new users, but modifying existing users requires additional permissions.",
    "no-match-found": "No match found",
    "no-matches-message": "No matches found",
    "no-permission-error": "You have no permission to perform this action. Contact HappyFox support.",
    "no-report-templates-found": "No report templates found",
    "no-reports-available": "No Reports available. Create a new report.",
    "no-results-found": "No results found",
    "no-results-found-visualization": "No results found. Check the date range selection and filters applied.",
    "no-subscriptions-found": "No Subscriptions found",
    "no-templates": "No Templates found",
    "no-token-register": "Please request an invite",
    "no-token-reset-password": "Please initiate the Forgot Password action",
    "no-users-available": "No users available",
    "no-visualization-page": "No visualization added to page yet",
    "no-visualization-settings": "No Visualization settings are available.",
    "object-data-type": "Object",
    "on-demand-sync-confirmation-{dataSource}": "You are about to initiate a sync for \"{dataSource}\". Do you want to proceed?",
    "on-demand-sync-failed-{dataSource}": "Failed to sync \"{dataSource}\" data source",
    "on-demand-sync-failure-message": "Manual sync has failed. Please try again.",
    "on-demand-sync-in-progress": "Sync in progress",
    "on-demand-sync-in-progress-message": "Sync is currently in progress.",
    "on-demand-sync-success-message": "Manual sync has completed successfully.",
    "on-demand-sync-success-{dataSource}": "Sync for \"{dataSource}\" data source has started",
    "on_demand_sync": "On-Demand Sync",
    "on_demand_sync_description": "Initiate On-Demand Sync for Data Sources",
    "options-selected": "{selectedOptionsCount, plural, =1 {1 {singularEntity}} other {# {pluralizedEntity}}} selected",
    "or": "or",
    "orientation": "Orientation",
    "others": "Others",
    "page-delete-confirmation-message": "Warning: All the visualizations related to this page will be deleted.",
    "page-name": "Page Name",
    "page-not-found": "Requested Report Page is not found",
    "pages": "Pages",
    "pareto": "Pareto",
    "password": "Password",
    "password-placeholder": "Account password",
    "password-policy": {
      "heading": "Password must contain",
      "maximum-character": "Maximum of 128 characters",
      "minimum-character": "Minimum of 8 characters",
      "one-lowercase": "At least one lowercase letter",
      "one-number": "At least one number",
      "one-special-character": "At least one special character",
      "one-uppercase": "At least one upper case letter"
    },
    "passwords-mismatch": "Passwords did not match",
    "percentile-correction-message": "Percentile value should be in between 0 and 100. Defaulted to 90.",
    "permissions": "Permissions",
    "preferences": "Preferences",
    "previous": "Previous",
    "reactivate": "Re-Activate",
    "reauthorize": "Reauthorize",
    "recipients": "Recipients",
    "refresh": "Refresh",
    "refresh-sync-status": "Refresh sync status",
    "refreshing": "Refreshing...",
    "regenerate-api-key": "Regenerate",
    "region": "Region",
    "register": "Register",
    "registration-success": "User registration is completed successfully",
    "release-notes": "Release Notes",
    "reload": "Reload",
    "remove": "Remove",
    "removed-datasources": "Removed Datasources",
    "replace-existing-data": "This import will replace {count} records",
    "report": "Report",
    "report-delete-confirmation-message": "Warning: All the pages and visualizations related to this report will be deleted.",
    "report-information": "Report Information",
    "report-name": "Report Name",
    "report-not-found": "Requested Report is not found",
    "report-subscription": "Report Subscription",
    "report-subscription-create-failed": "Failed to create subscription",
    "report-subscription-create-success": "Subscription created successfully",
    "report-subscription-delete-confirmation-message": "Are you sure you want to delete this subscription?",
    "report-subscription-delete-failed": "Failed to delete subscription",
    "report-subscription-delete-success": "Subscription deleted sucessfully",
    "report-subscription-dependants-failure": "Failed to check the report subscription dependants",
    "report-subscription-dependants-message": "Please remove users/clients as recipients in report subscriptions of this report before revoking their access",
    "report-subscription-save-confirmation-message": "If the recipients don't have access to the report, access will be given. Do you want to continue?",
    "report-subscription-update-failed": "Failed to update subscription",
    "report-subscription-update-success": "Subscription updated successfully",
    "report-subscriptions": "Report Subscriptions",
    "report-template-delete-confirmation-message": "Are you sure you want to delete this report template?",
    "report-template-delete-failed": "Failed to delete report template",
    "report-template-delete-success": "Report template deleted sucessfully",
    "report-template-update-failed": "Failed to update report template",
    "report-template-update-success": "Report template updated successfully",
    "report-templates": "Report Templates",
    "report-update-failed": "Failed to update report",
    "report-update-success": "Report updated successfully",
    "report-{name}-created": "Report \"{name}\" created successfully",
    "report-{name}-failed": "Failed to create the report \"{name}\"",
    "report-{name}-failed-as-template": "Failed to save report \"{name}\" as report template",
    "report-{name}-saved-as-{template}": "Report \"{name}\" saved as report template \"{template}\" successfully",
    "report_subscriptions": "Report Subscriptions",
    "report_subscriptions_description": "Manage Report Subscriptions for scheduling email reports",
    "reports": "Reports",
    "reset": "Reset",
    "reset-password": "Reset Password",
    "reset-password-success": "Password is changed successfully",
    "retry": "Retry",
    "review-fields": "Review fields",
    "role": "Role",
    "rows-empty-label": "No Row fields selected",
    "rows-label": "Rows Label",
    "salesforce": {
      "add-objects-help-text": "Click to add more objects",
      "select-objects": "Select objects to sync",
      "select-objects-description": "All selected objects data will be synced and be available for reporting.",
      "title": "Salesforce"
    },
    "sample-data": "Sample data:",
    "sample-data-message": "Showing 5 records of {count}",
    "sample-data-{dataset}": "Sample data for {dataset}:",
    "sample-visualizations": "Sample Visualizations",
    "save": "Save",
    "save-all": "Save All",
    "save-as-report-template": "Save as report template",
    "save-as-template": "Save as template",
    "save-template": "Save Template",
    "saving": "Saving...",
    "saving-as-report-template": "Saving as report template...",
    "schedule-frequency": "Schedule Frequency",
    "schedule-time": "Schedule Time",
    "search": "Search",
    "search-clients": "Search Clients",
    "search-report-pages": "Search reports or pages",
    "search-reports": "Search Reports",
    "search-users": "Search Users",
    "segmentation-chart-field-help-text": "Select a categorical field that needs to be segmented across the axes",
    "segmentation-chart-value-help-text": "Numeric fields that are plotted on both the axes",
    "segmentation-type": "Segmentation Type",
    "segments": "Segments",
    "select": "Select",
    "select-a-role": "Select a role",
    "select-account": "Select Account",
    "select-all": "Select all",
    "select-atleast-one-report": "Select atleast one report",
    "select-axis": "Select axis",
    "select-business-hours": "Select Business hours",
    "select-clients": "Select clients who can view the report",
    "select-color-mode": "Select color mode",
    "select-currency": "Select Currency",
    "select-data-sources": "Select Data Sources",
    "select-dataset-and-field": "Select Dataset and Field",
    "select-date": "Select Date",
    "select-date-range": "Select Date range",
    "select-days": "Select days",
    "select-format": "Select Format",
    "select-frequency": "Select Frequency",
    "select-hours": "Select Hours",
    "select-minutes": "Select Minutes",
    "select-page": "Select Page",
    "select-page-{visualizationName}": "Move ''{visualizationName}'' to a following page",
    "select-pages": "Select Pages",
    "select-recipients": "Select recipients",
    "select-report": "Select Report",
    "select-reports": "Select Reports",
    "select-role": "Select Role",
    "select-source-type": "Select Source Type",
    "select-time": "Select Time",
    "select-time-unit": "Select Time Unit",
    "select-timezone": "Select Timezone",
    "select-users": "Select users who can access the report",
    "selected-field": "Selected Field: ",
    "set-none": "Set none",
    "settings": "Settings",
    "settings-fields": "Settings Fields",
    "show-bars-only": "Show bars only",
    "show-raw-data": "Show Raw Data",
    "show-values": "Show Values",
    "show-values-help-text": "User can have their preference(more options in Page)",
    "show-visualization": "Show Visualization",
    "sort": {
      "created-at": {
        "asc": "Created - oldest to recent",
        "desc": "Created - recent to oldest"
      },
      "first-name": {
        "asc": "Firstname - A to Z",
        "desc": "Firstname - Z to A"
      },
      "last-name": {
        "asc": "Lastname - A to Z",
        "desc": "Lastname - Z to A"
      },
      "last-updated-at": {
        "asc": "Last Updated - oldest to recent",
        "desc": "Last Updated - recent to oldest"
      },
      "name": {
        "asc": "Name - A to Z",
        "desc": "Name - Z to A"
      }
    },
    "sort-options": "Sort options",
    "start": "Start",
    "start-date": "Start Date",
    "start-import": "Start Import",
    "start-new-import": "Start new import",
    "start-search-help-text": "Type at least two characters to start Search",
    "start-time": "Start Time",
    "status": "Status",
    "subject": "Subject",
    "submit": "Submit",
    "submitting": "Submitting...",
    "subscribe-to-report": "Subscribe to a report",
    "subscription-expired-message": "Your subscription has expired. Please contact your admin.",
    "suggested-fields": "Suggested Fields",
    "suspended": "Suspended",
    "switch-account": "Switch Account",
    "switch-to-account": "Switch to Account",
    "task": "Task",
    "template-delete-confirmation-message": "Are you sure you want to delete this template?",
    "template-description": "Template description",
    "template-pane-tooltip-text": "Drag and drop templates in the page",
    "template-reload-text": "Reload templates",
    "template-title-error-message": "Please provide value for Title",
    "templates-pane-empty-dataset": "Select a Dataset to see templates",
    "text-data-type": "Text",
    "theme-amcharts": "Default",
    "theme-dark": "Dark",
    "theme-dataviz": "Dataviz",
    "theme-frozen": "Frozen",
    "theme-kelly": "Kelly",
    "theme-light": "Light",
    "theme-material": "Material",
    "theme-microchart": "Microchart",
    "theme-moonrisekingdom": "Moonrise Kingdom",
    "theme-patterns": "Patterns",
    "theme-picker": {
      "choose-theme": "Choose Theme"
    },
    "theme-spiritedaway": "Spirited Away",
    "themes": "Themes",
    "tile-empty-label": "No Labels selected",
    "tile-empty-target-field": "No Label or Value selected",
    "tile-heading": "Heading",
    "tile-label-position": "Label Position",
    "tile-target-field": "Target Field",
    "tile-value-postfix": "Value postfix",
    "tile-value-prefix": "Value prefix",
    "time-data-type": "Time",
    "time-zone": "Time Zone",
    "times-used": "Times Used",
    "timestamp-data-type": "Timestamp",
    "timezone": "Timezone",
    "title": "Title",
    "to": "To",
    "top-left": "Top Left",
    "top-right": "Top Right",
    "total-percent": "Total %",
    "type": "Type",
    "type-to-search": "Type to search",
    "unselect-all": "Unselect all",
    "update": "Update",
    "update-business-hours": "Update business hours",
    "update-business-hours-confirmation-description-{dataSources}": "Datasource {dataSources} is removed and the following visualizations use these datasource(s) with this business hour:",
    "update-business-hours-dependant-visualizations-description": "List of Visualizations that will be affected by updating this business hours",
    "update-client": "Update Client",
    "update-report": "Update Report",
    "update-report-template": "Update Report Template",
    "update-subscription": "Update Subscription",
    "update-user": "Update User",
    "updating": "Updating...",
    "upload-csv-file": "Upload CSV file",
    "uploading": "Uploading...",
    "use-business-hours": "Use Business Hours",
    "use-report-template": "Use Report Template",
    "user": "User",
    "user-activate-failed": "Failed to activate user",
    "user-activate-success": "User activated successfully",
    "user-deactivate-failed": "Failed to deactivate user",
    "user-deactivate-success": "User deactivated successfully",
    "user-management": "User Management",
    "user-offline": "There is a problem with your internet connection.",
    "user-report-subscription-dependant-message": "User {user} is a recipient in report subscription(s) {reportSubscriptions}",
    "user-update-failed": "Failed to update user",
    "user-update-success": "User updated successfully",
    "username": "User Name",
    "username-email": "Email",
    "username-email-placeholder": "Enter email address here",
    "users": "Users",
    "validation": {
      "invalid-date-message": "Invalid Date",
      "invalid-number-configuration": "Configuration error - Maximum value cannot be less than minimum value.",
      "invalid-number-message": "Not a valid number",
      "invalid-number-min-max-message": "Maximum value cannot be less than minimum value.",
      "invalid-text-configuration": "Configuration error - Maximum character limit cannot be less than minimum character limit.",
      "mandatory-error-message": "This field is required.",
      "number-{max}-value-limit": "Please use number lesser than or equal to {max}.",
      "number-{min}-value-limit": "Please use number greater than or equal to {min}.",
      "number-{min}-{max}-value-limit": "Please use number between {min} and {max}.",
      "search-min-characters-error-message": "Please use minimum of {characters} {characters, plural, =1 {character} other {characters}} to search.",
      "select-date": "Please select a valid date"
    },
    "value": "Value",
    "value-aggregation": "Aggregation for value",
    "value-format": "Value format",
    "values": "Values",
    "values-empty-label": "No Value fields selected",
    "values-label": "Values Label",
    "view-all-reports": "View All Reports",
    "view-all-reports-tooltip-text": "This user has \"View all Reports\" enabled",
    "view-less": "View Less",
    "view-more": "View More",
    "view_raw_data": "View Raw Data",
    "view_raw_data_description": "View Raw Data",
    "visualization-data-failure": "Unable to fetch data",
    "visualization-delete-confirmation-message": "Are you sure you want to delete this visualization?",
    "visualization-examples": "Visualization Examples",
    "visualization-name": "Visualization Name",
    "visualization-no-edit": "You can't edit this visualization",
    "visualizations": "Visualizations",
    "visualizations-dependant-on-{businessHoursName}": "Visualizations using \"{businessHoursName}\"",
    "visualizations-limit-reached-message": "You have reached the maximum limit for visualizations",
    "visualizations-templates-limit-message": "Note: You cannot use templates as maximum limit for visualizations is reached",
    "visuzalization-not-found": "Requested Visualization is not found",
    "weekday": {
      "day-0": "Mon",
      "day-1": "Tue",
      "day-2": "Wed",
      "day-3": "Thu",
      "day-4": "Fri",
      "day-5": "Sat",
      "day-6": "Sun"
    },
    "weekly": "Weekly",
    "widget-filters": {
      "add-dataset-field": "Add Dataset field",
      "add-new-filter-value-{text}": "Select this option to add ''{text}''",
      "after next n days": "after next N days",
      "after next n days-{nValue}": "after next {nValue} days",
      "before last n days": "before last N days",
      "before last n days-{nValue}": "before last {nValue} days",
      "contains": "contains",
      "does not contain": "does not contain",
      "edit-dataset-field": "Edit Dataset field",
      "fetching-options": "Fetching options...",
      "greater than": "greater than",
      "greater than or equal to": "greater than or equal to",
      "is": "is",
      "is empty": "is empty",
      "is not": "is not",
      "is not empty": "is not empty",
      "is not set": "is not set",
      "is set": "is set",
      "last month": "Last Month",
      "last quarter": "Last Quarter",
      "last week": "Last Week",
      "last year": "Last Year",
      "less than": "less than",
      "less than or equal to": "less than or equal to",
      "options-fetch-failure": "Unable to fetch options",
      "select-data-set": "Select Dataset",
      "select-data-set-field": "Select Dataset Field",
      "select-data-source": "Select Data Source",
      "select-field": "Select field",
      "select-predicate": "Select predicate",
      "select-value": "Select value",
      "select-values": "Select values",
      "this month": "This Month",
      "this quarter": "This Quarter",
      "this week": "This Week",
      "this year": "This Year",
      "within last n days": "within last N days",
      "within last n days-{nValue}": "within last {nValue} days",
      "within next n days": "within next N days",
      "within next n days-{nValue}": "within next {nValue} days"
    },
    "word-cloud-field-help-text": "Select a field whose words needs to be visualized",
    "word-cloud-value-help-text": "Numeric field representing the weight of each word",
    "working-days": "Working days",
    "wrike": {
      "add-account": "Add Wrike Account",
      "insufficient-user-permissions": "Logged in Wrike User does not have \"Export account data\" or \"Generate user Activity Reports\" permissions. Please ensure you have these permissions and try again.",
      "linked-accounts": "Linked Wrike Accounts",
      "no-account-added": "No Wrike account added.",
      "title": "Wrike"
    },
    "x-axis": "X-Axis",
    "x-axis-empty-label": "No X-Axis fields selected",
    "x-axis-label": "X-Axis Label",
    "y-axis": "Y-Axis",
    "y-axis-empty-label": "No Y-Axis fields selected",
    "y-axis-label": "Y-Axis Label",
    "y-axis-left": "Y-Axis Left",
    "y-axis-left-empty-label": "No Y-Axis Left field selected",
    "y-axis-left-label": "Y-Axis Left Label",
    "y-axis-right": "Y-Axis Right",
    "y-axis-right-empty-label": "No Y-Axis Right field selected",
    "y-axis-right-label": "Y-Axis Right Label",
    "yes": "Yes",
    "yes-caps": "YES",
    "zendesk-source-type": "Zendesk"
  }]];
  _exports.default = _default;
});