define("happyfox-bi/visualizations/constant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WIDGETS_WITH_VALUE_LABELS = _exports.WIDGETS_WITH_LINE_SERIES = _exports.WIDGETS_WITH_LEGENDS = _exports.WIDGETS_WITH_AXIS = _exports.VISUALIZATION_OPTIONS = _exports.TIME_FORMAT_OPTIONS = _exports.TIMESTAMP_FORMAT_OPTIONS = _exports.NUMERIC_FIELD_TYPES = _exports.INCLUDE_PERCENTAGES_OPTIONS = _exports.DATE_FORMAT_OPTIONS = void 0;
  var TIMESTAMP_FORMAT_OPTIONS = [{
    id: 'year',
    name: 'Year'
  }, {
    id: 'quarter',
    name: 'Quarter'
  }, {
    id: 'quarter_with_year',
    name: 'Quarter & Year'
  }, {
    id: 'month',
    name: 'Month'
  }, {
    id: 'month_with_year',
    name: 'Month & Year'
  }, {
    id: 'week_of_month',
    name: 'Week'
  }, {
    id: 'week_with_month',
    name: 'Week & Month'
  }, {
    id: 'week_with_year',
    name: 'Week & Year'
  }, {
    id: 'day_of_week',
    name: 'Day of week'
  }, {
    id: 'date',
    name: 'Full Date'
  }, {
    id: 'hour_with_date',
    name: 'Hour & Full Date'
  }, {
    id: 'hour_of_day',
    name: 'Hour of day'
  }, {
    id: 'minute',
    name: 'Minute'
  }];
  _exports.TIMESTAMP_FORMAT_OPTIONS = TIMESTAMP_FORMAT_OPTIONS;
  var DATE_FORMAT_OPTIONS = [{
    id: 'year',
    name: 'Year'
  }, {
    id: 'quarter',
    name: 'Quarter'
  }, {
    id: 'quarter_with_year',
    name: 'Quarter & Year'
  }, {
    id: 'month',
    name: 'Month'
  }, {
    id: 'month_with_year',
    name: 'Month & Year'
  }, {
    id: 'week_of_month',
    name: 'Week'
  }, {
    id: 'week_with_month',
    name: 'Week & Month'
  }, {
    id: 'week_with_year',
    name: 'Week & Year'
  }, {
    id: 'day_of_week',
    name: 'Day of week'
  }, {
    id: 'date',
    name: 'Full Date'
  }];
  _exports.DATE_FORMAT_OPTIONS = DATE_FORMAT_OPTIONS;
  var TIME_FORMAT_OPTIONS = [{
    id: 'minutes',
    name: 'Minutes'
  }, {
    id: 'hours',
    name: 'Hours'
  }, {
    id: 'days',
    name: 'Days'
  }];
  _exports.TIME_FORMAT_OPTIONS = TIME_FORMAT_OPTIONS;
  var VISUALIZATION_OPTIONS = [{
    id: 'data_tile',
    displayValue: 'Data Tile'
  }, {
    id: 'text_tile',
    displayValue: 'Text Tile'
  }, {
    id: 'data_table',
    displayValue: 'Table'
  }, {
    id: 'pivot_table',
    displayValue: 'Pivot Table'
  }, {
    id: 'doughnut_chart',
    displayValue: 'Doughnut Chart'
  }, {
    id: 'bar_chart',
    displayValue: 'Bar Chart'
  }, {
    id: 'stacked_bar_chart',
    displayValue: 'Stacked Bar Chart'
  }, {
    id: 'hundred_percent_stacked_bar_chart',
    displayValue: '100% Stacked Bar Chart'
  }, {
    id: 'grouped_bar_chart',
    displayValue: 'Grouped Bar Chart'
  }, {
    id: 'area_chart',
    displayValue: 'Area Chart'
  }, {
    id: 'stacked_area_chart',
    displayValue: 'Stacked Area Chart'
  }, {
    id: 'multi_area_chart',
    displayValue: 'Multi Area Chart'
  }, {
    id: 'line_chart',
    displayValue: 'Line Chart'
  }, {
    id: 'multi_line_chart',
    displayValue: 'Multi Line Chart'
  }, {
    id: 'multi_axis_chart',
    displayValue: 'Multi Axis Chart'
  }, {
    id: 'map_chart',
    displayValue: 'Map Chart'
  }, {
    id: 'map_chart_usa',
    displayValue: 'Map Chart USA'
  }, {
    id: 'life_cycle_chart',
    displayValue: 'Life Cycle Chart'
  }, {
    id: 'segmentation_chart',
    displayValue: 'Segmentation Chart'
  }, {
    id: 'funnel_chart',
    displayValue: 'Funnel Chart'
  }, {
    id: 'pareto_chart',
    displayValue: 'Pareto Chart'
  }, {
    id: 'word_cloud',
    displayValue: 'Word Cloud'
  }, {
    id: 'gantt_chart',
    displayValue: 'Gantt Chart'
  }, {
    id: 'heat_map',
    displayValue: 'Heat Map'
  }, {
    id: 'tree_map',
    displayValue: 'Tree Map'
  }, {
    id: 'gauge_chart',
    displayValue: 'Gauge Chart'
  }];
  _exports.VISUALIZATION_OPTIONS = VISUALIZATION_OPTIONS;
  var INCLUDE_PERCENTAGES_OPTIONS = [{
    id: null,
    name: '------'
  }, {
    id: 'by_total',
    name: 'By Total'
  }, {
    id: 'by_row',
    name: 'By Row'
  }, {
    id: 'by_column',
    name: 'By Column'
  }];
  _exports.INCLUDE_PERCENTAGES_OPTIONS = INCLUDE_PERCENTAGES_OPTIONS;
  var NUMERIC_FIELD_TYPES = ['int', 'time', 'decimal', 'currency'];
  _exports.NUMERIC_FIELD_TYPES = NUMERIC_FIELD_TYPES;
  var WIDGETS_WITH_VALUE_LABELS = ['area_chart', 'multi_area_chart', 'stacked_area_chart', 'line_chart', 'multi_line_chart', 'bar_chart', 'grouped_bar_chart', 'multi_axis_chart', 'pareto_chart', 'heat_map'];
  _exports.WIDGETS_WITH_VALUE_LABELS = WIDGETS_WITH_VALUE_LABELS;
  var WIDGETS_WITH_LINE_SERIES = ['area_chart', 'line_chart', 'multi_line_chart', 'multi_area_chart', 'stacked_area_chart', 'multi_axis_chart'];
  _exports.WIDGETS_WITH_LINE_SERIES = WIDGETS_WITH_LINE_SERIES;
  var WIDGETS_WITH_AXIS = ['area_chart', 'multi_area_chart', 'stacked_area_chart', 'line_chart', 'multi_line_chart', 'bar_chart', 'grouped_bar_chart', 'stacked_bar_chart', 'multi_axis_chart', 'pareto_chart', 'hundred_percent_stacked_bar_chart'];
  _exports.WIDGETS_WITH_AXIS = WIDGETS_WITH_AXIS;
  var WIDGETS_WITH_LEGENDS = ['multi_area_chart', 'stacked_area_chart', 'multi_line_chart', 'grouped_bar_chart', 'stacked_bar_chart', 'multi_axis_chart', 'segmentation_chart', 'funnel_chart', 'doughnut_chart', 'hundred_percent_stacked_bar_chart'];
  _exports.WIDGETS_WITH_LEGENDS = WIDGETS_WITH_LEGENDS;
});