define("happyfox-bi/components/base-chart/component", ["exports", "@amcharts/amcharts4/core", "@amcharts/amcharts4/charts", "@amcharts/amcharts4/plugins/annotation", "happyfox-bi/utilities/math", "happyfox-bi/constants", "happyfox-bi/components/base-chart/constants", "happyfox-bi/utilities/number", "happyfox-bi/utilities/time"], function (_exports, am4core, am4charts, am4plugins_annotation, _math, _constants, _constants2, _number, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var CHART_DURATION_AXIS_FORMAT = 'y[y] M[m] d[d] h[h] m[min] s[sec]';

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['chart'],
    featuresService: Ember.inject.service('features'),
    intlService: Ember.inject.service('intl'),
    isNonInteractiveMode: false,
    // Used for screenshots of report subscriptions
    chartData: null,
    compareDateRangesList: null,
    profileDateRangePreferences: null,
    seekedDateRange: null,
    widgetModel: null,
    chartContainer: null,
    layoutName: 'components/base-chart',
    config: null,
    canCompareDateRange: false,
    canExportVisualizations: Ember.computed.reads('featuresService.exportVisualizations'),
    canViewRawData: Ember.computed.reads('featuresService.viewRawData'),
    fields: Ember.computed.reads('widgetModel.settings.fields'),
    isPreview: false,
    showValueLabels: false,
    yAxisExtraMax: _constants2.Y_AXIS_EXTRA_MAX,
    useSeriesNameAsTooltipLabel: false,
    drillFilters: null,
    drillCategories: null,
    init: function init() {
      this._super.apply(this, arguments); // Removing two way data binding so that each visualization component can have it's own way of data modification (if needed)


      this.set('data', _.cloneDeep(this.get('chartData')));
      this.set('compareDateRangesList', _.cloneDeep(this.get('compareDateRangesList')));
      this.set('config', _.cloneDeep(this.get('widgetModel.displaySettings')));
      this.processData();

      if (this.updateConfig) {
        this.updateConfig();
      }

      this.set('chart', null);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this$$ = this.$('.chart_container'),
          _this$$2 = _slicedToArray(_this$$, 1),
          chartContainer = _this$$2[0];

      this.set('chartContainer', chartContainer);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.dispose();
      }

      this._super.apply(this, arguments);
    },
    isChartComparison: Ember.computed('compareDateRangesList', 'canCompareDateRange', {
      get: function get() {
        return Ember.isPresent(this.get('compareDateRangesList')) && this.get('canCompareDateRange');
      }
    }),
    actualDateRange: Ember.computed('profileDateRangePreferences.{fromDate,toDate}', 'seekedDateRange.{fromDate,toDate}', {
      get: function get() {
        var seekedDateRange = this.get('seekedDateRange');
        var dateRangePreferences = this.get('profileDateRangePreferences');

        if (seekedDateRange && (seekedDateRange.fromDate || seekedDateRange.toDate)) {
          return seekedDateRange;
        }

        return dateRangePreferences;
      }
    }),
    disableSeekDateRange: Ember.computed('widgetModel.settings.query.drillDownFields', {
      get: function get() {
        var drillDownFields = this.get('widgetModel.settings.query.drillDownFields');
        return Ember.isPresent(drillDownFields);
      }
    }),
    canDrillDownByRawData: Ember.computed('viewRawData', 'isPreview', {
      get: function get() {
        return this.canViewRawData && !this.isPreview;
      }
    }),
    processData: function processData() {
      var _this = this;

      var visualization = this.get('widgetModel');
      var query = visualization.get('settings.query');
      var visualizationFields = visualization.get('settings.fields');
      var mainFieldNames = Ember.A().addObjects(query.get('rows')).addObjects(query.get('columns')).addObjects(query.get('values'));
      var dateAndTimestampFields = Ember.A();
      var keywordsTypeFields = Ember.A();
      var currentDrillDownFieldName = this.getCurrentDrillDownFieldName();

      if (currentDrillDownFieldName) {
        mainFieldNames.addObject(currentDrillDownFieldName);
      }

      mainFieldNames.forEach(function (fieldName) {
        var field = visualizationFields.findBy('name', fieldName);

        if (field.get('dataType') === 'timestamp' || field.get('dataType') === 'date') {
          dateAndTimestampFields.addObject(field);
        } else if (field.get('dataType') === 'keywords') {
          keywordsTypeFields.addObject(field);
        }
      });

      if (Ember.isPresent(keywordsTypeFields)) {
        this.removeStopWords(this.get('data'), keywordsTypeFields, visualization.get('displaySettings.stopWords'));
      }

      var dateFormat = visualization.get('preferences.dateFormat');
      this.sortDataByDateAndTimestampFields(this.get('data'), dateAndTimestampFields, dateFormat);

      if (this.get('isChartComparison')) {
        var compareDateRangesList = this.get('compareDateRangesList');
        compareDateRangesList.forEach(function (dateRangeField) {
          _this.sortDataByDateAndTimestampFields(dateRangeField.data, dateAndTimestampFields);
        });
      } // Remove null records or replace with NA based on configuration


      if (this.replaceNullInRowsAndColumns) {
        this.replaceNullInRowsAndColumns();
      } // Suffix time and percentage values with appropriate text


      if (this.transformValuesInData) {
        this.transformValuesInData();
      }
    },
    canManageBenchmarks: Ember.computed('featuresService.benchmarks', 'widgetModel.settings.benchmarks.[]', {
      get: function get() {
        return this.get('featuresService.benchmarks') && Ember.isPresent(this.get('widgetModel.settings.benchmarks'));
      }
    }),
    getCurrentDrillDownFieldName: function getCurrentDrillDownFieldName() {
      var drillCategories = this.drillCategories,
          drillFilters = this.drillFilters,
          visualization = this.widgetModel;
      var query = visualization.get('settings.query');

      if (Ember.isPresent(drillFilters)) {
        var drillDownFields = query.get('drillDownFields');
        var drillDownFieldName = drillCategories.get('length') ? drillDownFields[drillCategories.get('length') - 1] : query.get('rows.firstObject');
        return drillDownFieldName;
      }
    },
    getConfigValue: function getConfigValue(path, defaultValue) {
      var configValue = this.get("config.".concat(path));
      return Ember.isPresent(configValue) ? configValue : defaultValue;
    },
    addExportOption: function addExportOption(chart) {
      if (this.get('canExportVisualizations') && !this.get('isPreview') && !this.get('isNonInteractiveMode')) {
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.formatOptions.getKey("pdfdata").disabled = true; // FIXME: Disabled since `export data as pdf` is throwing some error.

        chart.exporting.useWebFonts = false; // FIXME: Disabled since it is not working with myriad pro

        chart.exporting.filePrefix = this.get('widgetModel.name');
      }
    },
    addAnnotationsOption: function addAnnotationsOption(chart) {
      if (this.get('featuresService.annotations') && !this.get('isNonInteractiveMode')) {
        chart.plugins.push(new am4plugins_annotation.Annotation());
      }
    },
    sortDataByDateAndTimestampFields: function sortDataByDateAndTimestampFields(data, fields) {
      var _this2 = this;

      var dateFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

      if (fields.get('length')) {
        data.sort(function (a, b) {
          for (var i = 0; i < fields.get('length'); i++) {
            var field = fields[i];
            var fieldName = field.get('name'); // Date format switcher format overrides the field format

            var timestampFieldFormat = _this2.getFieldDateFormat(field, dateFormat);

            var aValue = a[fieldName];
            var bValue = b[fieldName];

            if (timestampFieldFormat === 'day_of_week' || timestampFieldFormat === 'month') {
              var aValueOrder = void 0,
                  bValueOrder = void 0;

              if (timestampFieldFormat === 'day_of_week') {
                // For totals and percentages in pivot table, non day of the week values("Total") should be placed at the end
                aValueOrder = _constants2.DAY_OF_WEEKS_ORDER.findBy('text', aValue) ? _constants2.DAY_OF_WEEKS_ORDER.findBy('text', aValue).order : 8;
                bValueOrder = _constants2.DAY_OF_WEEKS_ORDER.findBy('text', bValue) ? _constants2.DAY_OF_WEEKS_ORDER.findBy('text', bValue).order : 8;
              } else if (timestampFieldFormat === 'month') {
                // For totals and percentages in pivot table, non month values("Total") should be placed at the end
                aValueOrder = _constants2.MONTHS_ORDER.findBy('text', aValue) ? _constants2.MONTHS_ORDER.findBy('text', aValue).order : 13;
                bValueOrder = _constants2.MONTHS_ORDER.findBy('text', bValue) ? _constants2.MONTHS_ORDER.findBy('text', bValue).order : 13;
              }

              if (aValueOrder < bValueOrder) {
                return -1;
              }

              if (aValueOrder > bValueOrder) {
                return 1;
              }
            } else if (timestampFieldFormat === 'quarter_with_year') {
              var _a$fieldName$split$ma = a[fieldName].split(' ').map(Number),
                  _a$fieldName$split$ma2 = _slicedToArray(_a$fieldName$split$ma, 2),
                  monthA = _a$fieldName$split$ma2[0],
                  yearA = _a$fieldName$split$ma2[1];

              var _b$fieldName$split$ma = b[fieldName].split(' ').map(Number),
                  _b$fieldName$split$ma2 = _slicedToArray(_b$fieldName$split$ma, 2),
                  monthB = _b$fieldName$split$ma2[0],
                  yearB = _b$fieldName$split$ma2[1]; // Compare by year first, then by month


              if (yearA !== yearB) {
                return yearA - yearB;
              } else {
                return monthA - monthB;
              }
            } else {
              if (aValue < bValue) {
                return -1;
              }

              if (aValue > bValue) {
                return 1;
              }
            }
          }

          return 0;
        });
      }
    },
    isXAxisDateType: function isXAxisDateType() {
      var fieldName = this.get('config.xAxis.key');
      var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);
      var fieldType = field.get('dataType');
      var isXAxisDateType = false;
      var fieldFormat = field.get('format');

      if (fieldType === 'timestamp') {
        isXAxisDateType = _constants2.FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS.includes(fieldFormat);
      } else if (fieldType === 'date') {
        isXAxisDateType = _constants2.FORMAT_SWITCHER_DATE_BASED_OPTIONS.includes(fieldFormat);
      }

      var dateFormat = this.get('widgetModel.preferences.dateFormat') || fieldFormat; // if x axis field is date type, but data is not in date format, then we should consider it as non date type
      // This happens when we select the date format as 'month', 'quarter' etc. in date format switcher on viz.

      if (isXAxisDateType && dateFormat && _constants2.NON_DATE_API_RESPONSE_FORMATS.includes(dateFormat)) {
        isXAxisDateType = false;
      }

      return isXAxisDateType;
    },
    setAxisRanges: function setAxisRanges(xAxis, valueAxis) {
      var yAxisConfig = this.get('config.yAxis');
      var yAxisMin = this.get('config.yAxisMin');
      var yAxisMax = this.get('config.yAxisMax');

      if (Ember.isPresent(yAxisConfig)) {
        if (Ember.isPresent(yAxisMin) || Ember.isPresent(yAxisMax)) {
          // Follows user defined min & max;
          valueAxis.strictMinMax = true;
        }

        if (Ember.isPresent(yAxisMin)) {
          valueAxis.min = yAxisMin;
        }

        if (Ember.isPresent(yAxisMax)) {
          valueAxis.max = yAxisMax;
        }
      }
    },
    prepareDateAxis: function prepareDateAxis(xAxis) {
      var _this3 = this;

      this.setUpXAxisFormat(xAxis);
      xAxis.renderer.labels.template.location = 0.5;
      var xAxisInterval = xAxis.baseInterval;

      if (xAxisInterval.timeUnit == 'day') {
        xAxis.renderer.axisFills.template.disabled = false;
        xAxis.renderer.axisFills.template.fill = am4core.color("#bbb");
        xAxis.renderer.axisFills.template.fillOpacity = 0.05; // Custom fill rule for axis ref -> https://www.amcharts.com/docs/v4/reference/dateaxis/#fillRule_method

        xAxis.fillRule = function (dataItem) {
          var date = new Date(dataItem.value);

          if ((date.getDay() == 0 || date.getDay() == 6) && xAxisInterval.timeUnit == "day" && xAxisInterval.count == 1) {
            dataItem.axisFill.visible = true;
          } else {
            dataItem.axisFill.visible = false;
          }
        };
      }

      if (!this.get('disableSeekDateRange')) {
        xAxis.events.on('endchanged', function (event) {
          var chart = event.target.chart;
          var startLocation = chart.cursor.xRange.start;
          var endLocation = chart.cursor.xRange.end;
          var startDate = xAxis.positionToDate(xAxis.toAxisPosition(startLocation));
          var endDate = xAxis.positionToDate(xAxis.toAxisPosition(endLocation));

          _this3.dateRangeChanged({
            startDate: startDate,
            endDate: endDate
          });
        });
      }
    },
    convertAndGetDateXAxisData: function convertAndGetDateXAxisData(data, axisKey) {
      data.forEach(function (item) {
        var fieldName = axisKey;
        var timestampString = item[fieldName]; // Data from BE is sent in user timezone

        if (timestampString) {
          item.hfFeDateinUserTz = moment(timestampString); // Moment has user timezone set. So this moment object will be the right timestamp

          item[fieldName] = new Date(timestampString); // This date object is not right, but needed for display purposes in UI.
        }
      });
      return data;
    },
    setUpXAxisFormat: function setUpXAxisFormat(axis) {
      var format = this.get('selectedDateFormat');
      axis.dateFormats.setKey("day", "MMM dd");
      axis.periodChangeDateFormats.setKey("month", "[bold]MMM dd, yyyy[/]");
      axis.periodChangeDateFormats.setKey("day", "[bold]MMM dd, yyyy[/]");
      axis.periodChangeDateFormats.setKey("year", "[bold]yyyy[/]");

      if (format === "month_with_year") {
        // incase of month with year, the label should always just be month
        axis.periodChangeDateFormats.setKey("day", "MMM");
      } else if (format === "year") {
        // incase year, the label should always just be the year
        axis.periodChangeDateFormats.setKey("day", "YYYY");
      }

      axis.dateFormats.setKey("hour", "hh a");
      axis.periodChangeDateFormats.setKey("hour", "[bold]MMM dd, hh a[/]");
    },
    getXAxisDateFormat: function getXAxisDateFormat(axis) {
      var baseInterval = axis.baseInterval.timeUnit;
      return axis.dateFormats.getKey(baseInterval);
    },
    getFieldType: function getFieldType(fieldName) {
      var fields = this.get('fields');
      var field = fields.findBy('name', fieldName);
      return field.get('dataType');
    },
    isFieldCurrencyType: function isFieldCurrencyType(fieldName) {
      var field = this.get('widgetModel.settings.fields').findBy('name', fieldName);

      if (field) {
        var isCurrencyField = field.get('dataType') === 'currency';
        var canFormatAsCurrency = _constants2.FIELD_TYPES_FORMATTED_AS_CURRENCY.includes(field.get('dataType')) && field.get('format') == 'currency';
        return isCurrencyField || canFormatAsCurrency;
      }

      return false;
    },
    isFieldTimeType: function isFieldTimeType(fieldKey) {
      var field = this.get('widgetModel.settings.fields').findBy('name', fieldKey);

      if (field) {
        return field.dataType === 'time';
      }

      return false;
    },
    canFormatTimeAsDuration: function canFormatTimeAsDuration(fieldKey) {
      var timeField = this.get('widgetModel.settings.fields').findBy('name', fieldKey);
      return timeField && timeField.timeUnit == 'seconds';
    },
    filterOnElementHit: function filterOnElementHit(filterConditions) {
      if (this.get('canViewRawData')) {
        var filters = {
          matchAll: filterConditions
        };
        this.showRawDataWithFilter(filters);
      }
    },
    prepareDateAxisCompareData: function prepareDateAxisCompareData(compareDateRangeField, fieldKey) {
      var actualDateRange = this.get('actualDateRange');
      var comparisonData = compareDateRangeField.data;
      var comparisonOffset = moment(actualDateRange.fromDate).diff(compareDateRangeField.fromDate, 'days');
      comparisonData.forEach(function (compareDatum) {
        var compareTimeStampString = moment(compareDatum[fieldKey]);
        compareDatum['originalTimeStamp'] = new Date(compareDatum[fieldKey]); // Used for displaying original timestamp in tooltip

        compareDatum[fieldKey] = compareTimeStampString.clone().add(comparisonOffset, 'days').toDate();
      });
      return comparisonData;
    },
    getCompareSeriesName: function getCompareSeriesName(field) {
      var fromDate = field.fromDate,
          toDate = field.toDate,
          dateRange = field.dateRange;

      if (dateRange == 'custom') {
        return "".concat(moment(fromDate).format(_constants.DEFAULT_DATE_FORMAT), " - ").concat(moment(toDate).format(_constants.DEFAULT_DATE_FORMAT));
      }

      var dateRangeLabel = this.get('intlService').lookup("date-ranges.".concat(dateRange.dasherize()));
      return "".concat(dateRangeLabel);
    },
    setAxisTitle: function setAxisTitle(axis, title) {
      if (Ember.isPresent(title)) {
        axis.title.text = title;
      }
    },
    setUnitsInAxisLabels: function setUnitsInAxisLabels(axis, axisUnit) {
      axis.renderer.labels.template.adapter.add("text", function (label, target) {
        if (target.dataItem && axisUnit) {
          return "".concat(label, " ").concat(_constants2.TIME_UNIT_SHORTHAND.findBy('id', axisUnit).unit);
        }

        return label;
      });
      axis.adapter.add('getTooltipText', function (text) {
        return "".concat(text, " ").concat(axisUnit);
      });
    },
    // dataKey refers to property/key that is a part of chart's data (Eg: 'originalTimeStamp');
    setSeriesTooltipText: function setSeriesTooltipText(series, dataKey) {
      var _this4 = this;

      var isDateXAxis = this.isXAxisDateType();
      var valueKey = series.dataFields.valueKey || series.dataFields.valueY;
      var useSeriesNameAsTooltipLabel = this.useSeriesNameAsTooltipLabel;
      var isValueTimeType = this.isFieldTimeType(valueKey);
      series.adapter.add('tooltipText', function (text, target) {
        var label, value;

        if (useSeriesNameAsTooltipLabel) {
          label = series.name;
        } else if (isDateXAxis) {
          var xAxis = target.xAxis;

          var axisFormat = _this4.getXAxisDateFormat(xAxis);

          label = Ember.isPresent(dataKey) ? "{".concat(dataKey, ".formatDate('").concat(axisFormat, "')}") : "{dateX.formatDate('".concat(axisFormat, "')}");
        } else {
          label = Ember.isPresent(dataKey) ? "{".concat(dataKey, "}") : "{categoryX}";
        }

        var tooltipDataItem = target.tooltipDataItem;

        if (isValueTimeType && Ember.isPresent(tooltipDataItem.valueY)) {
          value = _this4.formatValueByTime(tooltipDataItem.valueY, valueKey);
        } else {
          value = "{valueY}";
        }

        return "".concat(label, ": ").concat(value);
      });
    },
    setUpBenchmarks: function setUpBenchmarks(chart, valueAxis) {
      var _this5 = this;

      var _this$get = this.get('widgetModel.settings'),
          benchmarks = _this$get.benchmarks;

      chart.events.on("inited", function () {
        benchmarks.forEach(function (benchmark) {
          if (benchmark.field) {
            _this5.createBenchmark(valueAxis, benchmark);
          }
        });
      });
    },
    createBenchmark: function createBenchmark(valueAxis, benchmark) {
      var type = benchmark.type,
          valueType = benchmark.valueType,
          color = benchmark.color,
          from = benchmark.from,
          to = benchmark.to,
          value = benchmark.value,
          label = benchmark.label;
      var axisRange = valueAxis.axisRanges.create();
      var axisFill = axisRange.axisFill;
      var axisGrid = axisRange.grid;
      var axisRangeOpacity = 0.15;
      var bullet = new am4charts.AxisBullet();
      axisRange.bullet = bullet;
      var benchmarkBullet = axisRange.bullet.createChild(am4core.Triangle);
      benchmarkBullet.width = 10;
      benchmarkBullet.height = 10;

      if (benchmark.yAxis == 'right') {
        benchmarkBullet.rotation = -90;
        benchmarkBullet.dx = 5;
        valueAxis.renderer.labels.template.paddingLeft = 20;
      } else {
        benchmarkBullet.dx = -5;
        benchmarkBullet.rotation = 90;
        valueAxis.renderer.labels.template.paddingRight = 20;
      }

      benchmarkBullet.fill = am4core.color(color);
      benchmarkBullet.horizontalCenter = "middle";
      var tooltip = new am4core.Tooltip();
      tooltip.getFillFromObject = false;
      tooltip.background.fill = am4core.color(color);
      var benchmarkValueTypeLabel = this.get('intlService').lookup("edit-visualization.".concat(valueType));

      if (type == 'range') {
        axisRange.value = from;
        axisRange.endValue = to || valueAxis.max;
        axisFill.fill = am4core.color(color);
        axisFill.fillOpacity = axisRangeOpacity;
        axisGrid.strokeOpacity = 0;
        axisFill.tooltip = tooltip;
        axisFill.tooltipText = "Benchmark: ".concat(label, "\n[bold]Range: From ").concat(from, "[] to [bold]").concat(to, "[/]");
        axisFill.tooltipPosition = "pointer"; // Refer https://www.amcharts.com/docs/v4/concepts/axes/axis-ranges/#Tooltips_on_ranges

        axisFill.isMeasured = true;
        axisFill.interactionsEnabled = true;
      } else if (type == 'line') {
        var axisRangeValue = this.getBenchmarkLine(benchmark);
        axisRange.value = axisRangeValue;
        axisGrid.stroke = am4core.color(color);
        axisGrid.strokeWidth = 3;
        axisGrid.strokeOpacity = 1;
        axisGrid.tooltip = tooltip;

        if (valueType == 'average') {
          axisGrid.tooltipText = "Benchmark: ".concat(label, "\n[bold]").concat(benchmarkValueTypeLabel, "[/]");
        } else {
          axisGrid.tooltipText = "Benchmark: ".concat(label, "\n[bold]").concat(benchmarkValueTypeLabel, ": ").concat(value, "[/]");
        }

        axisGrid.tooltipPosition = "pointer";
      }

      axisGrid.above = true;
      axisRange.label.disabled = true; // Fixes multi axis charts undefined labels;
    },
    getBenchmarkLine: function getBenchmarkLine(benchmark) {
      if (benchmark.valueType == 'custom') {
        return benchmark.value;
      }

      var chartBenchmarkValue;
      var benchmarkValuesList = this.transformDataToCalculateBenchmark(benchmark.field);

      if (benchmark.valueType == 'percentile') {
        chartBenchmarkValue = (0, _math.getPercentile)(benchmarkValuesList, benchmark.value);
      } else {
        chartBenchmarkValue = (0, _math.getAverage)(benchmarkValuesList);
      }

      return chartBenchmarkValue;
    },
    transformDataToCalculateBenchmark: function transformDataToCalculateBenchmark(benchmark) {
      var data = this.get('data').slice();
      var benchmarkFieldName = benchmark.name;
      var transformedData = Ember.A();

      if (this.isStacked) {
        transformedData = this.transformStackedChartDataForBenchmark(data, benchmarkFieldName, transformedData);
      } else {
        transformedData = data.map(function (datum) {
          return datum[benchmarkFieldName];
        });
      }

      return transformedData;
    },
    transformStackedChartDataForBenchmark: function transformStackedChartDataForBenchmark(data, benchmarkFieldName, transformedData) {
      var _this$get2 = this.get('config'),
          xAxis = _this$get2.xAxis;

      var xAxisKey = xAxis.key;
      var xAxisPoints = Ember.A();
      data.forEach(function (datum) {
        var xAxisValue = datum[xAxisKey];
        xAxisPoints.addObject(xAxisValue);
      });
      var xAxisValuesMap = {};
      data.forEach(function (datum) {
        if (xAxisPoints.includes(datum[xAxisKey])) {
          var xAxisPointKey = datum[xAxisKey];
          var xAxisPointValue = datum[benchmarkFieldName];

          if (Ember.isPresent(xAxisPointValue)) {
            if (Ember.isPresent(xAxisValuesMap[xAxisPointKey])) {
              xAxisValuesMap[xAxisPointKey] += xAxisPointValue;
            } else {
              xAxisValuesMap[xAxisPointKey] = xAxisPointValue;
            }
          }
        }
      });
      Object.keys(xAxisValuesMap).forEach(function (xAxisPoint) {
        transformedData.pushObject(xAxisValuesMap[xAxisPoint]);
      });
      return transformedData;
    },
    createLegend: function createLegend(chart) {
      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true; // When using bullets, the legend-marker also has the bullet (from the series). Hence using default square shaped legend-marker

      chart.legend.position = 'top';

      if (this.get('isChartComparison')) {
        chart.legend.contentAlign = 'right';
      }

      chart.legend.marginBottom = 10;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(10, 10, 10, 10);
      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
    },
    getFiltersForFieldValue: function getFiltersForFieldValue(fieldName, value) {
      var filters = Ember.A();
      var field = this.get('fields').findBy('name', fieldName);
      var fieldType = field.get('dataType');
      var defaultFilter = {
        'field': fieldName,
        'predicate': fieldType === 'list' ? 'contains' : 'is',
        'value': value
      };

      if (value === _constants.NO_VALUE_SET) {
        defaultFilter.predicate = fieldType === 'list' ? 'is empty' : 'is not set';
        defaultFilter.value = null;
        filters.addObject(defaultFilter);
        return filters;
      }

      var fieldBuckets = field.buckets;
      var fieldCombineValues = field.combineValues;

      if (fieldBuckets) {
        // Bucket values
        var bucketForValue = fieldBuckets.get('choices').findBy('label', value);

        if (bucketForValue) {
          if (fieldType === 'time') {
            var timeUnit = field.get('timeUnit') || 'hours';
            filters.addObjects([{
              'field': fieldName,
              'predicate': 'greater than or equal to',
              'value': {
                'value': Number(bucketForValue['min']),
                'unit': timeUnit
              }
            }, {
              'field': fieldName,
              'predicate': 'less than',
              'value': {
                'value': Number(bucketForValue['max']),
                'unit': timeUnit
              }
            }]);
          } else {
            filters.addObjects([{
              'field': fieldName,
              'predicate': 'greater than or equal to',
              'value': Number(bucketForValue['min'])
            }, {
              'field': fieldName,
              'predicate': 'less than',
              'value': Number(bucketForValue['max'])
            }]);
          }
        } else if (fieldBuckets.get('canIncludeMissingValue') && value === fieldBuckets.get('missingValue')) {
          var filter = {
            'field': fieldName,
            'predicate': 'is not set',
            'value': null
          };
          filters.addObject(filter);
        } else {
          // TODO: Others for bucket is not handled for now.
          return [];
        }
      } else if (fieldCombineValues) {
        //combine values
        var _filter;

        var combineFieldDefault = fieldCombineValues.findBy('values', value);
        var combineField = fieldCombineValues.findBy('name', value) || combineFieldDefault;

        if (combineField) {
          var filterValues = Ember.A();

          if (combineField.name == 'default') {
            var nonDefaultCombineValues = fieldCombineValues.rejectBy('name', 'default');
            nonDefaultCombineValues.forEach(function (item) {
              filterValues.addObjects(item.values);
            });
            _filter = {
              field: fieldName,
              'predicate': fieldType === 'list' ? 'does not contain' : 'is not',
              'value': filterValues
            };
          } else {
            filterValues = combineField.values;
            _filter = {
              field: fieldName,
              'predicate': fieldType === 'list' ? 'contains' : 'is',
              'value': filterValues
            };
          }
        } else {
          _filter = defaultFilter;
        }

        filters.addObject(_filter);
      } else {
        filters.addObject(defaultFilter);
      }

      return filters;
    },
    // TODO: Move timestamp filters logic to 'getFiltersForFieldValue'
    getDrillFiltersForDateAxis: function getDrillFiltersForDateAxis(data, axisKey, axisValue) {
      var dateField = this.get('fields').findBy('name', axisKey);
      var filters = [];
      var filterCondition;

      if (axisValue === _constants.NO_VALUE_SET) {
        filterCondition = {
          field: axisKey,
          predicate: 'is not set',
          value: null
        };
        filters.push(filterCondition);
        return filters;
      } else {
        filterCondition = {
          field: axisKey,
          predicate: 'between',
          value: {
            from: null,
            to: null
          }
        };
      }

      var format = this.get('selectedDateFormat') || dateField.get('format');

      if (format === 'auto') {
        var actualDateRange = this.get('actualDateRange');
        format = this.getDateFormat(actualDateRange.fromDate, actualDateRange.toDate);
      }

      var startTime = data.findBy(axisKey, axisValue).hfFeDateinUserTz;
      var endTime;

      if (format === 'hour_with_date') {
        endTime = moment(startTime).endOf('hour');
      } else if (format === 'date') {
        endTime = moment(startTime).endOf('day');
      } else if (format === 'month_with_year') {
        endTime = moment(startTime).endOf('month');
      } else if (format === 'year') {
        endTime = moment(startTime).endOf('year');
      } else if (format === 'week_with_month' || format === 'week_with_year') {
        endTime = moment(startTime).endOf('week');
      }

      filterCondition.value.from = startTime.toISOString();
      filterCondition.value.to = endTime.toISOString();
      filters.addObject(filterCondition);
      return filters;
    },
    getDateFormat: function getDateFormat(fromDate, toDate) {
      var dateFormat = 'date';
      var difference = moment(toDate).diff(fromDate, 'days');

      if (difference > 700) {
        dateFormat = 'year';
      } else if (difference > 30) {
        dateFormat = 'month_with_year';
      } else if (difference < 2) {
        dateFormat = 'hour_with_date';
      }

      return dateFormat;
    },
    // Date format switcher format overrides the field format
    getFieldDateFormat: function getFieldDateFormat(field, vizDateFormat) {
      var format = field.get('format');

      if (vizDateFormat && vizDateFormat !== 'auto') {
        format = vizDateFormat;
      }

      return format;
    },
    formatDateAndTimeStampDataFields: function formatDateAndTimeStampDataFields(data) {
      var _this6 = this;

      var visualization = this.get('widgetModel');
      var dateRangeFieldName = visualization.get('settings.query.dateRangeField');
      var settings = visualization.settings;
      var dateAndTimestampFields = Ember.A();
      var dateFormat = visualization.get('preferences.dateFormat');
      var timestampDataFields = settings.fields.filterBy('dataType', 'timestamp').filter(function (field) {
        return _constants2.FORMAT_SWITCHER_TIMESTAMP_BASED_OPTIONS.includes(field.get('format'));
      });
      var dateFields = settings.fields.filterBy('dataType', 'date').filter(function (field) {
        return _constants2.FORMAT_SWITCHER_DATE_BASED_OPTIONS.includes(field.get('format'));
      });
      timestampDataFields = timestampDataFields.rejectBy('name', dateRangeFieldName);
      dateAndTimestampFields.addObjects(dateFields).addObjects(timestampDataFields); // Data sent from BE is in user timezone, so we should use moment.utc for displaying correct value in FE

      if (dateAndTimestampFields.length) {
        // If the API response format is not date, then we should not format it as date
        if (dateFormat && _constants2.NON_DATE_API_RESPONSE_FORMATS.includes(dateFormat)) {
          return;
        }

        data.forEach(function (dataItem) {
          // Format date range field value
          dataItem[dateRangeFieldName] = moment.utc(dataItem[dateRangeFieldName]).format('MMM DD YYYY, h:mm A'); // Format timestamp field values

          dateAndTimestampFields.forEach(function (field) {
            var name = field.get('name');
            var value = dataItem[name]; // When totals is enabled, value "Total" will be returned in data. So we should not format it as date or timestamp.

            if (Ember.isPresent(value) && value !== "Total") {
              var format = _this6.getFieldDateFormat(field, dateFormat);

              if (format === 'month_with_year') {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM YYYY');
              } else if (format === 'date') {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM DD YYYY');
              } else if (format === 'hour_with_date') {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM DD YYYY, h A');
              } else if (format === 'year') {
                dataItem[name] = moment.utc(dataItem[name]).format('YYYY');
              } else if (format === 'week_with_year') {
                dataItem[name] = moment.utc(dataItem[name]).format('wo [week of] YYYY');
              } else if (format === 'week_with_month') {
                var startDate = moment.utc(dataItem[name]);
                var endDate = moment.utc(startDate).add(6, 'days');
                dataItem[name] = "".concat(startDate.format('MMM DD YYYY'), " to ").concat(endDate.format('MMM DD YYYY'));
              } else if (!['hour_of_day', 'day_of_week', 'week_of_month', 'month', 'quarter_with_year', 'quarter'].includes(format)) {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM DD YYYY, h:mm A');
              }
            }
          });
        });
      }
    },
    getTimeFieldBaseUnit: function getTimeFieldBaseUnit(timeFieldKey) {
      var timeField = this.get('widgetModel.settings.fields').findBy('name', timeFieldKey);
      var unit = timeField.timeUnit || 'hours';
      return unit;
    },
    formatValuesInDataByCurrency: function formatValuesInDataByCurrency(data, fields) {
      var _this7 = this;

      var currencyFields = fields.filter(function (field) {
        return _this7.isFieldCurrencyType(field.name);
      });

      if (currencyFields.length) {
        var currencyFieldsNames = currencyFields.mapBy('name');
        data.forEach(function (datum) {
          currencyFieldsNames.forEach(function (fieldName) {
            var value = datum[fieldName];

            if (Ember.isPresent(value)) {
              var currencyField = currencyFields.findBy('name', fieldName);
              datum[fieldName] = _this7.formatValueByCurrencyField(value, currencyField);
            }
          });
        });
      }
    },
    formatDurationValuesInData: function formatDurationValuesInData(data, fields) {
      var _this8 = this;

      var timeTypeFields = fields.filterBy('dataType', 'time');

      if (timeTypeFields.length) {
        var timeFieldsNameFormatMap = {};
        timeTypeFields.forEach(function (field) {
          var fieldName = field.get('name');
          var format = field.get('timeUnit') || 'hours';
          timeFieldsNameFormatMap[fieldName] = format;
        });
        var timeFieldsNames = Object.keys(timeFieldsNameFormatMap);
        data.forEach(function (datum) {
          timeFieldsNames.forEach(function (fieldName) {
            var value = datum[fieldName];

            if (Ember.isPresent(value)) {
              var options = {};

              if (timeFieldsNameFormatMap[fieldName] !== 'seconds') {
                options.format = (0, _time.getLongHandDurationFormatByUnit)(timeFieldsNameFormatMap[fieldName]);
                options.precision = 2;
              }

              datum[fieldName] = (0, _time.humanizeDuration)(value, _this8.getTimeFieldBaseUnit(fieldName), options);
            }
          });
        });
      }
    },
    formatValueByCurrencyField: function formatValueByCurrencyField(value, field) {
      return (0, _number.getCurrencyValue)(value, field.currencyUnit, field.currencyFormat);
    },
    formatValueByTime: function formatValueByTime(value, fieldKey) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var timeField = this.get('widgetModel.settings.fields').findBy('name', fieldKey);
      var timeFieldUnit = timeField.timeUnit || 'hours';

      if (this.canFormatTimeAsDuration(fieldKey)) {
        options.format = CHART_DURATION_AXIS_FORMAT;
      } else {
        options.format = (0, _time.getLongHandDurationFormatByUnit)(timeFieldUnit);
      }

      if (timeFieldUnit !== 'seconds') {
        options.precision = 2;
      }

      var baseUnit = this.getTimeFieldBaseUnit(fieldKey);
      return (0, _time.humanizeDuration)(value, baseUnit, options);
    },

    /*
      - Applies number formatter on the element(chart,axis,series,etc.)
      - Format applied will be reflected in labels,tooltips,etc of the element
    */
    setElementNumberFormatAsCurrency: function setElementNumberFormatAsCurrency(element, valueKey) {
      var currencyField = this.get('widgetModel.settings.fields').findBy('name', valueKey);

      var currencyDetails = _constants.CURRENCY_UNIT_CODE_MAP.findBy('unit', currencyField.currencyUnit);

      element.numberFormatter = new am4core.NumberFormatter();
      element.numberFormatter.intlLocales = currencyField.currencyFormat;
      element.numberFormatter.numberFormat = {
        "style": "currency",
        "currency": currencyDetails.code,
        "minimumFractionDigits": 0
      };
    },
    setupDurationFormatter: function setupDurationFormatter(element, durationFieldKey) {
      var _this9 = this;

      var timeFieldUnit = this.getTimeFieldBaseUnit(durationFieldKey);

      var _DURATION_FORMATTER_U = _constants2.DURATION_FORMATTER_UNIT_MAP.findBy('id', timeFieldUnit),
          baseUnit = _DURATION_FORMATTER_U.baseUnit;

      if (Ember.isPresent(element.baseUnit)) {
        element.baseUnit = baseUnit;
        element.renderer.labels.template.adapter.add('text', function (text, target) {
          var dataItem = target.dataItem; // Strip off 0d 00h 00mins -> 0

          if (dataItem && dataItem.value >= 0) {
            var options = {
              largest: 3
            };
            return dataItem.value == 0 ? dataItem.value : _this9.formatValueByTime(dataItem.value, durationFieldKey, options);
          }

          return text;
        });
      } else {
        element.durationFormatter.baseUnit = baseUnit;
      }

      var defaultDurationFormats = element.durationFormatter.durationFormats;
      var customDurationFormats = this.getCustomDurationFormats(defaultDurationFormats);
      element.durationFormatter.durationFormats = customDurationFormats;
    },
    getCustomDurationFormats: function getCustomDurationFormats(defaultDurationFormats) {
      var customDurationFormats = {};
      Object.keys(defaultDurationFormats).forEach(function (unitKey) {
        customDurationFormats[unitKey] = _objectSpread({}, defaultDurationFormats[unitKey]);
        /*
          If closest denominator time unit is hour-> format will be `hh:mm` -> 09:30
          If closest denominator time unit is day -> format will be `d'd' hh:mm` -> Eg 2d 09:30
          ...etc
          Default format for hour with minutes is 'hh:mm'
          We change the format to "hh'h' mm'min'" for all possible options having hr/mins
          eg: 09:30 as 09h 30min
        */

        if (unitKey == 'minute' || unitKey == 'second') {
          // Denominator time unit: If value is 70 and base unit is `second` then denominator time unit is `minute`
          var denominatorDurationFormats = defaultDurationFormats[unitKey];
          Object.keys(denominatorDurationFormats).forEach(function (denominatorUnit) {
            if (denominatorUnit == 'minute') {
              customDurationFormats[unitKey][denominatorUnit] = "mm'mins' ss'sec'";
            } else if (denominatorUnit == 'hour') {
              customDurationFormats[unitKey][denominatorUnit] = "hh'h' mm'mins'";
            } else if (denominatorUnit == 'day' || denominatorUnit == 'week') {
              customDurationFormats[unitKey][denominatorUnit] = "d'd' hh'h' mm'mins'";
            } else if (denominatorUnit == 'month') {
              customDurationFormats[unitKey][denominatorUnit] = "M'm' d'd' hh'h' mm'mins'";
            } else if (denominatorUnit == 'year') {
              customDurationFormats[unitKey][denominatorUnit] = "y'y' M'm' d'd' hh'h' mm'mins'";
            }
          });
        }
      });
      return customDurationFormats;
    },
    removeStopWords: function removeStopWords(data, keywordsTypeFields, stopWords) {
      var dataToRemove = Ember.A();
      var fieldNames = keywordsTypeFields.mapBy('name');
      data.forEach(function (datum) {
        for (var i = 0; i < fieldNames.length; i++) {
          var fieldValue = datum[fieldNames[i]];

          if (fieldValue && stopWords && stopWords.includes(fieldValue.toLowerCase())) {
            dataToRemove.addObject(datum);
            break;
          }
        }
      });
      data.removeObjects(dataToRemove);
    }
  });

  _exports.default = _default;
});