define("happyfox-bi/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    fullName: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    reports: (0, _model.attr)(),
    features: (0, _model.attr)(),
    account: (0, _model.attr)('number'),
    isActive: (0, _model.attr)('boolean'),
    viewAllReports: (0, _model.attr)('boolean'),
    name: Ember.computed('fullName', 'email', {
      get: function get() {
        var fullName = this.fullName,
            email = this.email;
        return Ember.isBlank(fullName) ? email : fullName;
      }
    })
  });

  _exports.default = _default;
});