define("happyfox-bi/components/accordion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ltcvz2Ti",
    "block": "{\"symbols\":[\"item\",\"@accordionName\",\"&default\",\"@items\"],\"statements\":[[10,\"div\"],[14,0,\"accordion-container\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"details\"],[14,0,\"accordion-item\"],[15,3,[32,2]],[12],[2,\"\\n      \"],[10,\"summary\"],[14,0,\"accordion-header\"],[12],[2,\"\\n        \"],[18,3,[[30,[36,1],null,[[\"header\"],[[30,[36,0],[\"blank-yield\"],null]]]],[32,1]]],[2,\"\\n        \"],[1,[30,[36,2],[\"chevron-down\"],[[\"class\"],[\"chevron-icon\"]]]],[2,\"\\n      \"],[13],[2,\"\\n      \\n      \"],[10,\"div\"],[14,0,\"accordion-content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"accordion-content-inner\"],[12],[2,\"\\n          \"],[18,3,[[30,[36,1],null,[[\"content\"],[[30,[36,0],[\"blank-yield\"],null]]]],[32,1]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"inline-svg\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "happyfox-bi/components/accordion/template.hbs"
    }
  });

  _exports.default = _default;
});