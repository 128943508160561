define("happyfox-bi/components/empty-state-table/component", ["exports", "@happyfoxinc/hf-empty-state-table/components/hf-empty-state-table"], function (_exports, _hfEmptyStateTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hfEmptyStateTable.default;
    }
  });
});