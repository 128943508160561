define("happyfox-bi/components/pivot-table/component", ["exports", "happyfox-bi/components/base-chart/component", "happyfox-bi/components/pivot-table/axis-element", "happyfox-bi/mixins/resize-observer", "happyfox-bi/constants"], function (_exports, _component, _axisElement, _resizeObserver, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * config: { columnKeys: ['Party', 'Province'], rowKeys: ['Gender', 'Age Bin'], valueKey: 'value' }
   *
   * data: [{ 'Party': 'NDP', 'Province': 'British Columbia', 'Gender': 'Female', 'Age Bin': 50, 'value': 6 }, ...]
   *
   * Corresponding table will look as below:
   *
   *      |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|   --
   *      |              |                   NDP                  |       Conservative          |     |
   *      |              | - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|     |- - Column axis
   *      |              |  British Columbia  | Quebec  | Ontario | British Columbia  | Ontario |     |
   *      |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|   --
   *      |         | 50 |          6         |    8    |    7    |         4         |    5    |
   *      |  Female |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|
   *      |         | 40 |                    |    3    |    2    |                   |    1    |
   *      |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   *      |         | 50 |          16        |    18   |    17   |         14        |    15   |
   *      |   Male  |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|
   *      |         | 40 |          11        |    13   |    12   |         9         |    10   |
   *      |- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -|
   *
   *      |              |
   *       - - - - - - -
   *             |
   *          Row Axis
   */
  var PT_AXIS_ELEMENT_KEY = '_pt_axis_element_key_';
  var PT_AXIS_ELEMENT_LABEL = 'Values';
  var PT_VALUE_KEY = '_pt_value_key_';
  var EXPERIMENTAL_FEATURE_TOTALS = true;

  var _default = _component.default.extend(_resizeObserver.default, {
    intlService: Ember.inject.service('intl'),
    classNames: ['chart', 'mod-pivot-table'],
    availableAxisElementId: 1,
    // Keeps the next available id for newly created axis element
    rowAxisElements: null,
    // Nested row axis elements representing row axis
    columnAxisElements: null,
    // Nested column axis elements representing column axis
    rowAxisRenderData: null,
    // Two dimensional array of axis elements to render row axis
    columnAxisRenderData: null,
    // Two dimensional array of axis elements to render column axis
    cellValuesRenderData: null,
    // Two dimensional array of values to render cell values
    normalizedData: null,
    rowAxisKeys: Ember.computed.reads('config.rowKeys'),
    columnAxisKeys: Ember.computed.reads('config.columnKeys'),
    valueKey: Ember.computed.reads('config.valueKey'),
    rowAxisLabels: Ember.computed.reads('config.rowLabels'),
    columnAxisLabels: Ember.computed.reads('config.columnLabels'),
    showAxisLabels: Ember.computed.reads('config.showAxisLabels'),
    alignValues: 'center',
    sortedColumnId: null,
    // Track the currently sorted column
    isAscending: true,
    // Track the sort order
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var rowAxisElements = Ember.A();
      var columnAxisElements = Ember.A();
      var rowAxisKeys = this.get('rowAxisKeys');
      var columnAxisKeys = this.get('columnAxisKeys');
      var valueKey = this.get('valueKey'); // For each record in data, corresponding nested axis elements are created if not present for both
      // row and column axes

      this.get('normalizedData').forEach(function (datum) {
        var rowLeafAxisElement = _this.createAxisElements(datum, true, rowAxisKeys, rowAxisElements, 0);

        var columnLeafAxisElement = _this.createAxisElements(datum, false, columnAxisKeys, columnAxisElements, 0); // After axis elements are created for one data record, corresponding cell value is stored in
        // row and column leaf axis elements with id as `R<rowLeafAxisElementId>C<columnLeafAxisElementId>`
        // This is efficient to render cell values.


        var cellValue = datum[valueKey];
        var valueIdentifier = "R".concat(rowLeafAxisElement.get('id'), "C").concat(columnLeafAxisElement.get('id'));
        rowLeafAxisElement.get('axisValues').pushObject(Ember.Object.create({
          id: valueIdentifier,
          value: cellValue
        }));
      });
      this.setProperties({
        rowAxisElements: rowAxisElements,
        columnAxisElements: columnAxisElements
      }); // After all axis elements are created, the nested axis elements are not easy to render in template.
      // They will be converted into two dimensional arrays for ease of rendering in function createRenderData.

      this.createRenderData();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.canUseResizeObserver()) {
        this.setupResizeObserver('.pivot-table_container');
      } else {
        this.addEventsToDetectTableResize();
      }

      this.createLeftWrapperTable();
      this.createTopWrapperTable();
      this.initializeTableEvents();
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.pivot-table_container').off('scroll');
      this.$('.pivot-table_top-wrapper').off('scroll');
      this.$('.pivot-table_left-body-wrap').off('scroll');
      this.$('.pivot-table tbody').off('mouseover mouseleave', 'td', this.highlightRowAndColumn);

      if (this.get('resizeObserver')) {
        this.removeResizeObserver();
      } else {
        this.removeEventsToDetectTableResize();
      }
    },

    /* Overriding sortDataByDateAndTimestampFields() from Base chart as we want to sort by date/timestamp fields only when there are no sorting options */
    sortDataByDateAndTimestampFields: function sortDataByDateAndTimestampFields(data, fields) {
      var dateFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var visualization = this.get('widgetModel');
      var query = visualization.get('settings.query');

      if (!Ember.isPresent(query.sortBy)) {
        this._super(data, fields, dateFormat);
      }
    },
    initializeTableEvents: function initializeTableEvents() {
      this.$('.pivot-table_container').on('scroll', Ember.run.bind(this, this.onTableScroll));
      this.$('.pivot-table_top-wrapper').on('scroll', Ember.run.bind(this, this.onColumnScroll));
      this.$('.pivot-table_left-body-wrap').on('scroll', Ember.run.bind(this, this.onRowScroll));
      var wrapperRowHeaderElements = this.$('.pivot-table_left-body tbody tr');
      var wrapperColumnHeaderElements = this.$('.pivot-table_top-header thead tr');
      this.$('.pivot-table tbody').on('mouseover mouseleave', 'td', Ember.run.bind(this, this.highlightRowAndColumn, wrapperRowHeaderElements, wrapperColumnHeaderElements));
    },
    onElementResize: function onElementResize() {
      this.recomputeWrapperTableDimensions();
    },
    recomputeWrapperTableDimensions: function recomputeWrapperTableDimensions() {
      this.setWrapperRowsDimensions();
      this.setWrapperColumnsDimensions();
    },
    onFullScreenChange: function onFullScreenChange() {
      var _this2 = this;

      Ember.run.later(function () {
        _this2.recomputeWrapperTableDimensions();
      }, 1000);
    },
    onVisualizationResize: function onVisualizationResize() {
      var _this3 = this;

      if (this.get('resizedWidget.id') == this.get('widgetModel.id')) {
        Ember.run.later(function () {
          _this3.recomputeWrapperTableDimensions();
        }, 400);
      }
    },
    addEventsToDetectTableResize: function addEventsToDetectTableResize() {
      this.addObserver('isWidgetTileInFullScreen', this, 'onFullScreenChange');
      this.addObserver('resizedWidget.width', this, 'onVisualizationResize');
      this.addObserver('resizedWidget.height', this, 'onVisualizationResize');
    },
    removeEventsToDetectTableResize: function removeEventsToDetectTableResize() {
      this.removeObserver('isWidgetTileInFullScreen', this, 'onFullScreenChange');
      this.removeObserver('resizedWidget.width', this, 'onVisualizationResize');
      this.removeObserver('resizedWidget.height', this, 'onVisualizationResize');
    },
    highlightRowAndColumn: function highlightRowAndColumn(wrapperRowHeaderElements, wrapperColumnHeaderElements, event) {
      var cellElement = Ember.$(event.target);
      var cellRow = Ember.$(cellElement.parent());
      var cellRowHeaderElements = wrapperRowHeaderElements[cellRow.index()];
      var leafColumnHeaderElements = wrapperColumnHeaderElements.last();
      var cellPosition = cellRow.children('.pivot-table_cell-value').index(cellElement);

      if (event.type == 'mouseover') {
        Ember.$(cellRowHeaderElements).find('th:last').addClass('is-highlighted');
        Ember.$(leafColumnHeaderElements).find('th').eq(cellPosition).addClass('is-highlighted');
      } else {
        Ember.$(cellRowHeaderElements).find('th:last').removeClass('is-highlighted');
        Ember.$(leafColumnHeaderElements).find('th').eq(cellPosition).removeClass('is-highlighted');
      }
    },
    // For given data record, axis elements are created if not present and leaf axis element is returned.
    createAxisElements: function createAxisElements(datum, isRowAxisElement, axisKeys, axisElements, level, parentAxisElement) {
      var leaf;
      var axisKey = axisKeys[level];
      var axisElementValue = datum[axisKey];
      var axisElement = axisElements.findBy('value', axisElementValue);

      if (!axisElement) {
        var availableAxisElementId = this.get('availableAxisElementId');
        this.set('availableAxisElementId', availableAxisElementId + 1);
        var showAxisLabels = this.get('showAxisLabels');
        axisElement = _axisElement.default.create({
          id: availableAxisElementId,
          axisFieldName: axisKey,
          value: axisElementValue,
          parentAxisElement: parentAxisElement,
          level: level,
          isRowAxisElement: isRowAxisElement,
          showAxisLabels: showAxisLabels
        });
        axisElements.addObject(axisElement);

        if (parentAxisElement) {
          parentAxisElement.get('subAxisElements').addObject(axisElement);
        }
      }

      if (level < axisKeys.length - 1) {
        level++;
        leaf = this.createAxisElements(datum, isRowAxisElement, axisKeys, axisElement.get('subAxisElements'), level, axisElement);
      } else {
        leaf = axisElement;
      }

      return leaf;
    },
    onTableScroll: function onTableScroll(event) {
      var currentScrollPositionTop = event.target.scrollTop;
      var currentScrollPositionLeft = event.target.scrollLeft;
      var topWrapperContainer = this.$('.pivot-table_top-wrapper');
      var leftWrapperBody = this.$('.pivot-table_left-body-wrap');
      topWrapperContainer.scrollLeft(currentScrollPositionLeft);
      leftWrapperBody.scrollTop(currentScrollPositionTop);
    },
    onColumnScroll: function onColumnScroll(event) {
      var currentScrollPositionLeft = event.target.scrollLeft;
      var tableContainer = this.$('.pivot-table_container');
      tableContainer.scrollLeft(currentScrollPositionLeft);
    },
    onRowScroll: function onRowScroll(event) {
      var currentScrollPositionTop = event.target.scrollTop;
      var tableContainer = this.$('.pivot-table_container');
      tableContainer.scrollTop(currentScrollPositionTop);
    },
    // Creates a Wrapper table from Pivot Table rows
    createLeftWrapperTable: function createLeftWrapperTable() {
      var pivotTable = this.$('.pivot-table');
      var pivotTableHeaderRows = pivotTable.find('thead tr');
      var pivotTableBodyRows = pivotTable.find('tbody tr');
      var leftWrapperHead = this.$('.pivot-table_left-header').find('thead');
      var leftWrapperBody = this.$('.pivot-table_left-body').find('tbody'); // delete the child nodes 

      leftWrapperBody.empty();
      pivotTableBodyRows.each(function () {
        var leftWrapperBodyRow = Ember.$(document.createElement('tr'));
        var pivotTableBodyThElements = Ember.$(this).find('th');
        pivotTableBodyThElements.each(function () {
          Ember.$(this).clone().appendTo(leftWrapperBodyRow);
        });
        leftWrapperBody.append(leftWrapperBodyRow);
      });
      pivotTableHeaderRows.each(function () {
        var leftWrapperHeadRow = Ember.$(document.createElement('tr')); // Header Labels are differentiated from other th elements by 'data-is-head'

        var pivotTableHeadThElements = Ember.$(this).find('th[data-is-head]');
        pivotTableHeadThElements.each(function () {
          Ember.$(this).clone().appendTo(leftWrapperHeadRow);
        });
        leftWrapperHead.append(leftWrapperHeadRow);
      });
      this.setWrapperRowsDimensions();
    },
    // function to find findColumnId recursively, we need this, as sometimes the click happens in the svg, g or the path element.
    findColumnId: function findColumnId(element) {
      // .data wouldn't work
      var columnAxisId = Ember.$(element).attr('data-column-id');

      if (!columnAxisId) {
        var parentElement = Ember.$(element).parent();

        if (parentElement.length > 0) {
          columnAxisId = this.findColumnId(parentElement);
        }
      }

      return columnAxisId;
    },
    createTopWrapperTable: function createTopWrapperTable() {
      var pivotTable = this.$('.pivot-table');
      var pivotTableHeaderHeight = pivotTable.find('thead').innerHeight();
      var firstRowThWidthInBody = this.getFirstRowThWidthInBody();
      this.$('.pivot-table_top-wrapper').css("left", firstRowThWidthInBody);
      this.$('.pivot-table_top-wrapper').outerHeight(pivotTableHeaderHeight);
      var pivotTableHeaderRows = pivotTable.find('thead tr');
      var topWrapperHead = this.$('.pivot-table_top-header').find('thead');
      topWrapperHead.empty();
      var self = this;
      pivotTableHeaderRows.each(function () {
        var topWrapperHeadRow = Ember.$(document.createElement('tr')); // Get header th elements that are not rows/column labels

        var pivotTableHeadThElements = Ember.$(this).find('th').not('th[data-is-head]');

        if (Ember.isPresent(pivotTableHeadThElements)) {
          pivotTableHeadThElements.each(function (_, element) {
            var clonedElement = Ember.$(element).clone(true);
            topWrapperHeadRow.append(clonedElement);
          }); // Add event listener only if the pivot table is 1 by 1

          var shouldAddEventListener = self.get('rowAxisKeys').length === 1 && self.get('columnAxisKeys').length === 1;

          if (shouldAddEventListener) {
            // Add event listener to all children of the last row of topWrapperHeadRow
            topWrapperHeadRow.children().each(function (_, child) {
              Ember.$(child).on('click', function (event) {
                var columnAxisId = self.findColumnId(event.target);

                if (self.get('sortedColumnId') === Number(columnAxisId)) {
                  // if the user clicks the same column header, toggle the sort order
                  self.toggleProperty('isAscending');
                } else {
                  // if the user clicks a new column header, reset sortedColumnId and sort it desc .
                  self.setProperties({
                    sortedColumnId: Number(columnAxisId),
                    isAscending: false
                  });
                } // eslint-disable-next-line ember/jquery-ember-run


                self.setProperties({
                  rowAxisElements: self.sortRowsByColumn(self.get('rowAxisElements'), columnAxisId)
                }); // eslint-disable-next-line ember/jquery-ember-run

                self.createRenderData();
                Ember.run.later(function () {
                  self.createLeftWrapperTable();
                  self.createTopWrapperTable();
                }, 0);
              });
            });
          }

          topWrapperHead.append(topWrapperHeadRow);
        }
      });
      this.setWrapperColumnsDimensions();
    },
    sortRowsByColumn: function sortRowsByColumn(rowAxisElements, colIndex) {
      var _this4 = this;

      var colRegex = new RegExp("C".concat(colIndex, "$"));
      return rowAxisElements.sort(function (a, b) {
        var aValue = a.axisValues.find(function (axis) {
          return colRegex.test(axis.id);
        });
        var bValue = b.axisValues.find(function (axis) {
          return colRegex.test(axis.id);
        }); // If a value is missing, consider it as 0

        var aVal = aValue ? aValue.value : 0;
        var bVal = bValue ? bValue.value : 0;
        return _this4.get('isAscending') ? aVal - bVal : bVal - aVal;
      });
    },
    setWrapperRowsDimensions: function setWrapperRowsDimensions() {
      var pivotTableContainer = this.$('.pivot-table_container');
      var pivotTable = this.$('.pivot-table');
      var pivotTableHeight = pivotTable.height();
      var pivotTableHeaderRows = pivotTable.find('thead tr');
      var pivotTableBodyRows = pivotTable.find('tbody tr');
      var leftWrapperHead = this.$('.pivot-table_left-header').find('thead');
      var leftWrapperBody = this.$('.pivot-table_left-body').find('tbody');
      pivotTableBodyRows.each(function (index, bodyRow) {
        var leftWrapperBodyRow = Ember.$(leftWrapperBody).find('tr').eq(index);
        var pivotTableBodyThElements = Ember.$(bodyRow).find('th');
        pivotTableBodyThElements.each(function (i, el) {
          var leftWrapperBodyTh = Ember.$(leftWrapperBodyRow).find('th').eq(i);
          Ember.$(leftWrapperBodyTh).outerWidth(Ember.$(el).outerWidth());
          Ember.$(leftWrapperBodyTh).outerHeight(Ember.$(el).outerHeight());
        });
        leftWrapperBodyRow.height(Ember.$(bodyRow).outerHeight());
      });
      pivotTableHeaderRows.each(function (index, headerRow) {
        var leftWrapperHeadRow = Ember.$(leftWrapperHead).find('tr').eq(index); // Rows/Columns labels are differentiated from other th elements by 'data-is-head'

        var pivotTableHeadThElements = Ember.$(this).find('th[data-is-head]');
        pivotTableHeadThElements.each(function (i, el) {
          var leftWrapperHeadTh = Ember.$(leftWrapperHeadRow).find('th').eq(i);
          Ember.$(leftWrapperHeadTh).outerWidth(Ember.$(el).outerWidth());
          Ember.$(leftWrapperHeadTh).outerHeight(Ember.$(el).outerHeight());
        });
        leftWrapperHeadRow.height(Ember.$(headerRow).outerHeight());
      });
      var firstRowThWidthInBody = this.getFirstRowThWidthInBody();
      this.$('.pivot-table_left-wrapper').outerWidth(firstRowThWidthInBody);
      this.$('.pivot-table_left-header-wrap').outerHeight(pivotTable.find('thead').innerHeight()); // If container's height is more than the table, then there wont be any overflow/scroll, so we set the height of the left-wrapper-body same as that of table-body height

      if (pivotTableContainer.height() > pivotTableHeight) {
        this.$('.pivot-table_left-body-wrap').height(pivotTable.find('tbody').height());
      } else {
        /* If pivot table's height is more than the container, we need to make our left-wrapper-body element scrollable
          To do so, the height of the left-wrapper-body should be same as that of the 'visible' height of pivot table's body element after which they scroll.
          Visible height of table body = Table Container height - Table header height
        */
        this.$('.pivot-table_left-body-wrap').height(pivotTableContainer.height() - pivotTable.find('thead').height());
      }
    },
    setWrapperColumnsDimensions: function setWrapperColumnsDimensions() {
      var pivotTable = this.$('.pivot-table');
      var pivotTableWidth = pivotTable.outerWidth();
      var pivotTableHeaderHeight = pivotTable.find('thead').innerHeight();
      var firstRowThWidthInBody = this.getFirstRowThWidthInBody();
      this.$('.pivot-table_top-wrapper').css("left", firstRowThWidthInBody);
      this.$('.pivot-table_top-wrapper').outerHeight(pivotTableHeaderHeight);
      /* Enclosing table inside a container of fixed width.
       Refer: https://stackoverflow.com/questions/14767459/why-td-width-is-not-working-or-not-followed
      */

      this.$('.pivot-table_top-header-wrap').css("width", pivotTableWidth - firstRowThWidthInBody);
      var pivotTableHeaderRows = pivotTable.find('thead tr');
      var topWrapperHead = this.$('.pivot-table_top-header').find('thead');
      pivotTableHeaderRows.each(function (index) {
        var topWrapperHeadRow = Ember.$(topWrapperHead).find('tr').eq(index); // Get header th elements that are not rows/column labels

        var pivotTableHeadThElements = Ember.$(this).find('th').not('th[data-is-head]');

        if (Ember.isPresent(pivotTableHeadThElements)) {
          pivotTableHeadThElements.each(function (i, el) {
            var topWrapperHeadTh = Ember.$(topWrapperHeadRow).find('th').eq(i);
            Ember.$(topWrapperHeadTh).outerWidth(Ember.$(el).outerWidth());
            Ember.$(topWrapperHeadTh).outerHeight(Ember.$(el).outerHeight());
          });
          topWrapperHeadRow.height(Ember.$(this).outerHeight());
          topWrapperHeadRow.width(Ember.$(this).outerWidth());
        }
      });
      Ember.$(topWrapperHead).height(pivotTableHeaderHeight);
    },
    getFirstRowThWidthInBody: function getFirstRowThWidthInBody() {
      var pivotTable = this.$('.pivot-table');
      var firstRow = pivotTable.find('tbody tr').eq(0);
      var firstRowTh = firstRow.find('th');
      var firstRowThWidth = 0;
      firstRowTh.each(function () {
        firstRowThWidth += Ember.$(this).outerWidth();
      });
      return firstRowThWidth;
    },
    replaceNullInRowsAndColumns: function replaceNullInRowsAndColumns() {
      var rowKeys = this.get('config.rows') ? this.get('config.rows').mapBy('key') : [];
      var columnKeys = this.get('config.columns') ? this.get('config.columns').mapBy('key') : [];
      this.get('data').forEach(function (item) {
        if (rowKeys) {
          rowKeys.forEach(function (key) {
            if (item[key] === null) {
              item[key] = _constants.NO_VALUE_SET;
            }
          });
        }

        if (columnKeys) {
          columnKeys.forEach(function (key) {
            if (item[key] === null) {
              item[key] = _constants.NO_VALUE_SET;
            }
          });
        }
      });
    },
    createRenderData: function createRenderData() {
      this.createColumnAxisRenderData();
      this.createRowAxisRenderData();
    },
    createColumnAxisRenderData: function createColumnAxisRenderData() {
      // Creating empty two dimensional array for column axis render data
      var columnAxisRenderData = Ember.A();
      this.get('columnAxisKeys').forEach(function () {
        columnAxisRenderData.addObject(Ember.A());
      });
      var showAxisLabels = this.get('showAxisLabels'); // First value in this two dimensional array is the empty cell in the pivot table

      var firstCellRowSpan = this.get('columnAxisKeys.length');
      var firstCellColumnSpan = this.get('rowAxisKeys.length'); // Show first cell with row axis label only when when there are only 1 row and 1 column

      var shouldForceFillFirstCell = firstCellRowSpan === 1 && firstCellColumnSpan === 1 && !showAxisLabels;
      var firstCellValue = shouldForceFillFirstCell ? this.get('rowAxisLabels')[0] : null;
      columnAxisRenderData[0].addObject({
        value: firstCellValue,
        rowSpan: firstCellRowSpan,
        columnSpan: firstCellColumnSpan,
        isHeaderLabel: true
      });

      if (showAxisLabels) {
        this.get('columnAxisLabels').forEach(function (label, index) {
          columnAxisRenderData[index].addObject({
            value: label,
            rowSpan: 1,
            columnSpan: 1,
            isHeaderLabel: true
          });
        });
        var rowAxisLabels = Ember.A();
        this.get('rowAxisLabels').forEach(function (label) {
          rowAxisLabels.addObject({
            value: label,
            rowSpan: 1,
            columnSpan: 1,
            isHeaderLabel: true
          });
        });
        rowAxisLabels.addObject({
          value: null,
          rowSpan: 1,
          columnSpan: 1,
          isHeaderLabel: true
        });
        columnAxisRenderData.addObject(rowAxisLabels);
      } // Creating column axis render data


      this.fillColumnAxisRenderData(this.get('columnAxisElements'), columnAxisRenderData);
      this.set('columnAxisRenderData', columnAxisRenderData);
    },
    // A recursive function to fill the two dimensional array(columnAxisRenderData) with
    // column axis elements in exact order to render
    // This function uses preorder traversal algorithm from graphs
    fillColumnAxisRenderData: function fillColumnAxisRenderData(columnAxisElements, columnAxisRenderData) {
      var _this5 = this;

      if (EXPERIMENTAL_FEATURE_TOTALS) {
        // Pushing Total to the end at each level
        var totalAxisElement = columnAxisElements.findBy('value', 'Total');

        if (totalAxisElement) {
          var totalAxisElementIndex = columnAxisElements.indexOf(totalAxisElement);
          columnAxisElements.splice(totalAxisElementIndex, 1);

          if (this.get('config.showTotals')) {
            columnAxisElements.push(totalAxisElement);
          }
        }

        var averageAxisElement = columnAxisElements.findBy('value', 'Average');

        if (averageAxisElement) {
          var averageAxisElementIndex = columnAxisElements.indexOf(averageAxisElement);
          columnAxisElements.splice(averageAxisElementIndex, 1);

          if (this.get('config.showAverages')) {
            columnAxisElements.push(averageAxisElement);
          }
        }
      }

      columnAxisElements.forEach(function (axisElement) {
        var level = axisElement.get('level');
        columnAxisRenderData[level].addObject(axisElement);
        /*
          For each column axis element (both parent and leaf), align value is set
          This info is helpful to align body cell values based on leaf column axis element's
          align value (see generateRowCellsRenderData)
        */

        axisElement.set('align', _this5.get('alignValues'));
        var subAxisElements = axisElement.get('subAxisElements'); // If not leaf, recursively call fillColumnAxisRenderData using nested subAxisElements

        if (Ember.isPresent(subAxisElements)) {
          _this5.fillColumnAxisRenderData(subAxisElements, columnAxisRenderData);
        }
      });
    },
    createRowAxisRenderData: function createRowAxisRenderData() {
      var rowAxisRenderData = Ember.A();
      var cellValuesRenderData = Ember.A(); // Creating row axis render data and cell values render data

      this.generateRowAxisRenderData(this.get('rowAxisElements'), rowAxisRenderData, cellValuesRenderData);
      this.setProperties({
        rowAxisRenderData: rowAxisRenderData,
        cellValuesRenderData: cellValuesRenderData
      });
    },
    // A recursive function to generate 2 two dimensional arrays(rowAxisRenderData and cellValuesRenderData) with
    // values in exact order to render
    // This function uses preorder traversal algorithm from graphs
    generateRowAxisRenderData: function generateRowAxisRenderData(rowAxisElements, rowAxisRenderData, cellValuesRenderData) {
      var _this6 = this;

      var singleRowRenderData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : Ember.A();

      if (EXPERIMENTAL_FEATURE_TOTALS) {
        // Pushing Total to the end at each level
        var totalAxisElement = rowAxisElements.findBy('value', 'Total');

        if (totalAxisElement) {
          var totalAxisElementIndex = rowAxisElements.indexOf(totalAxisElement);
          rowAxisElements.splice(totalAxisElementIndex, 1);

          if (this.get('config.showTotals')) {
            rowAxisElements.push(totalAxisElement);
          }
        }

        var averageAxisElement = rowAxisElements.findBy('value', 'Average');

        if (averageAxisElement) {
          var averageAxisElementIndex = rowAxisElements.indexOf(averageAxisElement);
          rowAxisElements.splice(averageAxisElementIndex, 1);

          if (this.get('config.showAverages')) {
            rowAxisElements.push(averageAxisElement);
          }
        }
      }

      rowAxisElements.forEach(function (axisElement) {
        singleRowRenderData.addObject(axisElement);
        var subAxisElements = axisElement.get('subAxisElements');

        if (Ember.isPresent(subAxisElements)) {
          // If not leaf, recursively call generateRowAxisRenderData using nested subAxisElements
          _this6.generateRowAxisRenderData(subAxisElements, rowAxisRenderData, cellValuesRenderData, singleRowRenderData);
        } else {
          // If a leaf is reached, a single row render data is generated.
          rowAxisRenderData.addObject(singleRowRenderData.slice()); // Clear the singleRowRenderData array, since it is used recursively and next row render data starts with empty array.

          singleRowRenderData.clear(); // Creating corresponding row cell values

          var rowCellsRenderData = _this6.generateRowCellsRenderData(axisElement);

          cellValuesRenderData.addObject(rowCellsRenderData);
        }
      });
    },
    // Generates row cell values, given correspong row leaf axis element
    generateRowCellsRenderData: function generateRowCellsRenderData(rowAxisLeafElement) {
      var rowCellsData = Ember.A();
      var rowLeafId = rowAxisLeafElement.get('id');
      var columnsDepth = this.get('columnAxisKeys.length');
      var columnLeafLevelAxisElements = this.get('columnAxisRenderData')[columnsDepth - 1]; // columns at leaf level will be rendered in the order of columnLeafLevelAxisElements
      // Filtering by id to exclude empty cell if there is only one level of columns

      columnLeafLevelAxisElements.filterBy('id').forEach(function (columnAxisLeafElement) {
        var columnLeafId = columnAxisLeafElement.get('id');
        var cellValueObject = rowAxisLeafElement.get('axisValues').findBy('id', "R".concat(rowLeafId, "C").concat(columnLeafId));
        var cellValue = cellValueObject ? cellValueObject.get('value') : null; // Each cell must know its corresponding leaf row/column element

        rowCellsData.pushObject({
          id: cellValueObject ? "R".concat(rowLeafId, "C").concat(columnLeafId) : null,
          value: cellValue,
          rowAxisLeafElement: rowAxisLeafElement,
          columnAxisLeafElement: columnAxisLeafElement,
          align: columnAxisLeafElement.get('align')
        });
      });
      return rowCellsData;
    },
    updateConfig: function updateConfig() {
      var _this7 = this;

      this.handleValueAlignment(); // To prevent config changes in constants used for mock

      var config = Object.assign({}, this.get('config')); // Include percentage key in values

      if (config.showPercentages) {
        var valueFields = config.values;
        var valuePercentageFields = valueFields.map(function (field) {
          return Ember.Object.create({
            key: "".concat(field.key, "Percentage"),
            label: "".concat(field.label, " Percentage")
          });
        });
        valuePercentageFields.forEach(function (percentageField, index) {
          // inserting percentage value after the count value
          config.values.splice(index * 2 + 1, 0, {
            key: percentageField.key,
            label: percentageField.label
          });
        });
      }

      if (this.get('isChartComparison')) {
        var dataCollection = Ember.A();
        var actualDateRangeData = this.get('data');
        actualDateRangeData.forEach(function (datum) {
          datum.compareType = _this7.get('intlService').lookup('current-period');
        });
        dataCollection.addObjects(actualDateRangeData);
        var compareDateRangesList = this.get('compareDateRangesList');
        compareDateRangesList.forEach(function (dateRangeField) {
          var compareDateRangeData = dateRangeField.data;
          compareDateRangeData.forEach(function (datum) {
            datum.compareType = _this7.getCompareSeriesName(dateRangeField);
          });
          dataCollection.addObjects(compareDateRangeData);
        });
        this.set('normalizedData', this.normalizeConfig(config, dataCollection));
        /* FIXME:
          1. Currently, the logic for adding a comparison column is split across 'updateConfig' & 'normalizeConfig' methods.
          2. Since we want comparison column elements to be the leaf, this logic is performed after 'normalizeConfig' method (where multiple values from config is normalized to multiple table columns).
           ^ points must be revisited to make it more generic.
        */

        var comparisonColumn = Ember.Object.create({
          label: this.get('intlService').lookup('comparison'),
          key: 'compareType'
        });
        config.columns.addObject(comparisonColumn);
      } else {
        this.set('normalizedData', this.normalizeConfig(config, this.get('data')));
      }

      config.rowKeys = config.rows.mapBy('key');
      config.rowLabels = config.rows.mapBy('label');
      config.columnKeys = config.columns.mapBy('key');
      config.columnLabels = config.columns.mapBy('label');
      config.valueKey = config.values[0].key;
      this.set('config', config);
    },
    handleValueAlignment: function handleValueAlignment() {
      var visualizationFields = this.widgetModel.get('settings.fields');
      var valueKeys = this.get('config.values').mapBy('key');
      var valueFields = valueKeys.map(function (key) {
        return visualizationFields.findBy('name', key);
      });
      /*
        - In Pivot table, there cannot be comibnation of aggregation / non aggregation fields in values
        - So we are using 'some' check to see if there are any number type fields in values
      */

      var isNumberTypeValues = valueFields.some(function (field) {
        return _constants.NUMBER_TYPE_FIELDS.includes(field.dataType);
      });

      if (isNumberTypeValues) {
        this.set('alignValues', 'right');
      }
    },
    normalizeConfig: function normalizeConfig(config, dataCollection) {
      var numberOfRows = config.rows.length;
      var numberOfColumns = config.columns.length;
      var numberOfValues = config.values.length;

      if (!numberOfRows || !numberOfColumns || numberOfValues > 1) {
        var preNormalizeValues = config.values;
        var preNormalizeValueKeys = config.values.mapBy('key');
        var preNormalizeRowKeys = config.rows.mapBy('key');
        var preNormalizeColumnKeys = config.columns.mapBy('key');
        var preNormalizedAllKeys = Ember.A().addObjects(preNormalizeRowKeys).addObjects(preNormalizeColumnKeys).addObjects(preNormalizeValueKeys);
        var axisElement = {
          key: PT_AXIS_ELEMENT_KEY,
          label: PT_AXIS_ELEMENT_LABEL
        };

        if (!numberOfRows) {
          // To prevent config changes in constants used for mock
          config.rows = config.rows.slice();
          config.rows.push(axisElement);
        } else {
          // To prevent config changes in constants used for mock
          config.columns = config.columns.slice();
          config.columns.push(axisElement);
        }

        config.values = [{
          key: PT_VALUE_KEY,
          label: null
        }];
        var normalizedRowKeys = config.rows.mapBy('key');
        var normalizedColumnKeys = config.columns.mapBy('key');
        var normalizedValueKeys = config.values.mapBy('key');
        var normalizedAllKeys = Ember.A().addObjects(normalizedRowKeys).addObjects(normalizedColumnKeys).addObjects(normalizedValueKeys);
        var preNormalizeDataKeysReferenceIndexes = normalizedAllKeys.map(function (key) {
          return preNormalizedAllKeys.indexOf(key);
        });
        var normalizedDataResults = Ember.A();
        dataCollection.forEach(function (datum) {
          var normalizedDatum = {};
          preNormalizeDataKeysReferenceIndexes.forEach(function (referenceIndex, index) {
            if (referenceIndex !== -1) {
              var key = normalizedAllKeys[index];
              normalizedDatum[key] = datum[key];
            }
          });
          preNormalizeValueKeys.forEach(function (key) {
            var normalizedDatumObject = Object.assign({}, normalizedDatum);
            var customElementValue = preNormalizeValues.findBy('key', key).label;
            normalizedDatumObject[PT_AXIS_ELEMENT_KEY] = customElementValue;
            normalizedDatumObject[PT_VALUE_KEY] = datum[key]; // FIXME: Adding comparison related information in datum should be ideally moved outside this generic method.

            if (datum.compareType) {
              normalizedDatumObject['compareType'] = datum.compareType;
            }

            normalizedDataResults.push(normalizedDatumObject);
          });
        });
        return normalizedDataResults;
      } else {
        return dataCollection;
      }
    },
    transformValuesInData: function transformValuesInData() {
      var _this8 = this;

      var data = this.get('data');
      var visualization = this.get('widgetModel');
      var visualizationFields = visualization.get('settings.fields');
      var valueKeys = this.get('config.values').mapBy('key');
      var valueFields = valueKeys.map(function (key) {
        return visualizationFields.findBy('name', key);
      });

      if (this.get('isChartComparison')) {
        var compareDateRangesList = this.get('compareDateRangesList');
        compareDateRangesList.forEach(function (dateRangeField) {
          _this8.formatDurationValuesInData(dateRangeField.data, valueFields);

          _this8.suffixPercentageValues(dateRangeField.data);

          _this8.formatDateAndTimeStampDataFields(dateRangeField.data);

          _this8.formatValuesInDataByCurrency(dateRangeField.data, valueFields);
        });
      }

      this.formatDurationValuesInData(data, valueFields);
      this.suffixPercentageValues(data);
      this.formatDateAndTimeStampDataFields(data);
      this.formatValuesInDataByCurrency(data, valueFields);
    },
    suffixPercentageValues: function suffixPercentageValues(data) {
      // Transform percentage values by suffixing with %
      var config = this.get('config');

      if (config.showPercentages) {
        var valueKeys = config.values.mapBy('key');
        var valuePercantageKeys = valueKeys.map(function (key) {
          return "".concat(key, "Percentage");
        });
        data.forEach(function (datum) {
          valuePercantageKeys.forEach(function (key) {
            var datumPercentageValue = datum[key];

            if (Ember.isPresent(datumPercentageValue)) {
              datum[key] = "".concat(datumPercentageValue, " %");
            }
          });
        });
      }
    },

    /*
        Reasons why this skipping is needed.
        - In case of Multiple values, `normalizeConfig` method converts each `value` ( in query.values) to PT_AXIS_ELEMENT_KEY/PT_VALUE_KEY format.
          When converted, axisFieldName no longer refers to any rows/columns/values in the query. We use this discrepancy to skip sending `values` in drill down.
        - Since we dont want any info about total/average in drilldown, we skip this
    */
    canSkipDrillDownField: function canSkipDrillDownField(fieldName, axisElementValue) {
      var query = this.widgetModel.settings.query;
      var rowsAndColumns = Ember.A().addObjects(query.rows).addObjects(query.columns);
      return !rowsAndColumns.includes(fieldName) || ['Total', 'Average'].includes(axisElementValue);
    },
    // Create filters by traversing from leaf to top based on availability of parentAxisElement
    createDrillFiltersFromAxisElement: function createDrillFiltersFromAxisElement(axisElement) {
      var filters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.A();
      var axisFieldName = axisElement.axisFieldName,
          parentAxisElement = axisElement.parentAxisElement,
          value = axisElement.value;
      var canSkipDrillDownField = this.canSkipDrillDownField(axisFieldName, value);

      if (!canSkipDrillDownField) {
        filters.addObjects(this.getFiltersForFieldValue(axisFieldName, value));
      }

      if (parentAxisElement) {
        this.createDrillFiltersFromAxisElement(parentAxisElement, filters);
      }

      return filters;
    },
    actions: {
      drillDown: function drillDown(cell) {
        if (Ember.isPresent(cell) && this.canDrillDownByRawData) {
          var rowAxisLeafElement = cell.rowAxisLeafElement,
              columnAxisLeafElement = cell.columnAxisLeafElement;
          var rowFilters = this.createDrillFiltersFromAxisElement(rowAxisLeafElement);
          var columnFilters = this.createDrillFiltersFromAxisElement(columnAxisLeafElement);
          var filters = Ember.A().addObjects(rowFilters).addObjects(columnFilters); // Filters will be empty won't be there for 'Others' in buckets

          if (Ember.isPresent(filters)) {
            this.filterOnElementHit(filters);
          }
        }
      }
    }
  });

  _exports.default = _default;
});