define("@happyfoxinc/hf-empty-state-table/components/hf-empty-state-table", ["exports", "@happyfoxinc/hf-empty-state-table/templates/components/hf-empty-state-table"], function (_exports, _hfEmptyStateTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _hfEmptyStateTable.default,
    isLeftMarginRequired: false,
    isDarkMode: false,
    classNameBindings: ['isDarkMode:hf-is-dark-mode'],
    classNames: ['hf-empty-state-table'],
    emptyStateMessage: null,
    emptyStateHeader: null,
    showEmptyStateActionButton: false,
    emptyStateActionButtonText: null,
    dummyArray: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.dummyArray = this.dummyArray || [1, 2, 3, 4];
    },
    actions: {
      onClick: function onClick() {
        if (this.emptyStateAction) {
          this.emptyStateAction();
        }
      }
    }
  });

  _exports.default = _default;
});