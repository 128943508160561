define("happyfox-bi/components/add-visualization-field/constant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TIME_FORMAT_OPTIONS = _exports.TIMESTAMP_FORMAT_OPTIONS = _exports.FORMULA_FIELD_DATA_TYPES = _exports.FIELD_TYPES = _exports.DATE_FORMAT_OPTIONS = _exports.AGGREGATION_TYPES = void 0;
  var FIELD_TYPES = [{
    id: 'aggregation',
    name: 'Aggregation'
  }, {
    id: 'formula_field',
    name: 'Formula'
  }, {
    id: 'aggregation_formula',
    name: 'Aggregation Formula'
  }];
  _exports.FIELD_TYPES = FIELD_TYPES;
  var FORMULA_FIELD_DATA_TYPES = [{
    id: 'text',
    name: 'Text'
  }, {
    id: 'decimal',
    name: 'Decimal'
  }, {
    id: 'time',
    name: 'Time'
  }, {
    id: 'timestamp',
    name: 'Timestamp'
  }];
  _exports.FORMULA_FIELD_DATA_TYPES = FORMULA_FIELD_DATA_TYPES;
  var AGGREGATION_TYPES = [{
    id: null,
    name: 'No Aggregation'
  }, {
    id: 'count',
    name: 'Count'
  }, {
    id: 'distinct_count',
    name: 'Distinct Count'
  }, {
    id: 'avg',
    name: 'Average'
  }, {
    id: 'sum',
    name: 'Sum'
  }, {
    id: 'percentile',
    name: 'Percentile'
  }];
  _exports.AGGREGATION_TYPES = AGGREGATION_TYPES;
  var TIMESTAMP_FORMAT_OPTIONS = [{
    id: 'quarter',
    name: 'Quarter'
  }, {
    id: 'quarter_with_year',
    name: 'Quarter & Year'
  }, {
    id: 'month',
    name: 'Month'
  }, {
    id: 'week_of_month',
    name: 'Week'
  }, {
    id: 'day_of_week',
    name: 'Day of week'
  }, {
    id: 'date',
    name: 'Full Date'
  }, {
    id: 'hour_of_day',
    name: 'Hour of day'
  }];
  _exports.TIMESTAMP_FORMAT_OPTIONS = TIMESTAMP_FORMAT_OPTIONS;
  var DATE_FORMAT_OPTIONS = [{
    id: 'quarter',
    name: 'Quarter'
  }, {
    id: 'quarter_with_year',
    name: 'Quarter & Year'
  }, {
    id: 'month',
    name: 'Month'
  }, {
    id: 'week_of_month',
    name: 'Week'
  }, {
    id: 'day_of_week',
    name: 'Day of week'
  }, {
    id: 'date',
    name: 'Full Date'
  }];
  _exports.DATE_FORMAT_OPTIONS = DATE_FORMAT_OPTIONS;
  var TIME_FORMAT_OPTIONS = [{
    id: 'seconds',
    name: 'Auto'
  }, {
    id: 'minutes',
    name: 'Minutes'
  }, {
    id: 'hours',
    name: 'Hours'
  }, {
    id: 'days',
    name: 'Days'
  }];
  _exports.TIME_FORMAT_OPTIONS = TIME_FORMAT_OPTIONS;
});